import React, { useState, useEffect } from "react"
import MainContainer from "../../components/MainContainer"
import {
  Grid,
  Grow,
  CircularProgress,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { useLocation } from "react-router-dom"
import casadiAPI from "../../utils/api"
import { errorHandling } from "../../utils/api"
import { useContextValue } from "../../state/context"
import { useHistory } from "react-router-dom"
import { images } from "../../resources"
import Dialog from '../../components/Dialog'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooksOutlined';
import BooksModal from '../../components/BooksModal';
import DisplayTimer from "../../components/DisplayTimer"

const useStyles = makeStyles(() => ({
  card: {
    width: "210px",
    height: "210px",
    marginRight: "30px",
    marginBottom: "30px",
  },
  media: {
    marginTop: "10px",
    height: "100px",
    width: "auto",
    marginLeft: "auto",
    marginRight: "auto",
  },
  loadingScreen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    flexDirection: "column",
    height: "100%",
  },
  productName: {
    color: "#00415E",
    font: "Oxygen",
    fontSize: 18,
    fontWeight: 700,
    borderRadius: 5,
  },
  openAccordion: {
    color: "white",
    font: "Oxygen",
    fontSize: 18,
    fontWeight: 700,
    backgroundColor: "#00415E",
    borderRadius: 5,
  },
  modalButton: {
    width: 150,
    margin: 20,
  },
  description: {
    width: 20,
    height: 20,
    position: "absolute",
    right: 10,
    top: 10,
    zIndex: 10
  },
  cardTitle: {
    fontSize: '1.5em'
  },
  emptySubproducts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '30px',
  },
  emptyIconWrapper: {
    width: '120px',
    height: '120px',
    overflow: 'hidden',
  },
  emptyIcon: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }
}))

const SubProducts = () => {
  // eslint-disable-next-line
  const [userProducts, setUserProducts] = useState(null)
  const [subProduct, setSubProduct] = useState(null)
  const [books, setBooks] = useState(null)
  const [openBooks, setOpenBooks] = useState(false)
  const [learnigProducts, setLearnigProducts] = useState(null)
  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line
  const [selectedProduct, setSelectedProduct] = useState({})
  const [open, setOpen] = useState(false)
  const [passedPopup, setPassedPopup] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const classes = useStyles()
  const { state } = useLocation()
  // eslint-disable-next-line
  const [{ }, dispatch] = useContextValue()
  const { item } = state
  const history = useHistory()
  const userProductId = localStorage.userProductId || null

  useEffect(() => {
    setLoading(true)
    getSubProducts()
    localStorage.setItem('openMaterials', false)
    // eslint-disable-next-line
  }, [])



  const getSubProducts = () => {

    checkSubProductBooks()

    casadiAPI
      .get(`/sub-products/${item.obj.id}/${userProductId}`)
      .then((response) => {

        if (response.status === 200) {
          let products;
          if (Array.isArray(response.data.data)) {
            products = [...response.data.data]
          } else {
            products = Object.values(response.data.data)
          }

          // const products = [...response.data.data]
          let subProductArray = []
          let learnigProductsArray = []
          const options = products.map((obj, index) => {
            if (obj) {
              if (obj.category === 'learning') {
                learnigProductsArray.push(obj)
              } else {
                subProductArray.push(obj)
              }
              return {
                ...obj,
              }
            } else return false
          })
          setUserProducts(options)
          setSubProduct(subProductArray)
          setLearnigProducts(learnigProductsArray)
          setLoading(false)
        }
      })
      .catch((err) => {
        errorHandling(err.response.config.url, err.response.status, err.response.data.message)
        setLoading(false)
      })
  }

  const storeQData = async (value, type, subProduct) => {
    let ending = ''
    ending = subProduct.category === 'exam' ? 'TQ' : 'SQ'

    if (subProduct.category === 'hard_questions') {
      // ending = 'SQ'
      // type = 1
      // item.quizType = 1
      value.data.category = subProduct.category;
      value.data.type = type;
      localStorage.removeItem('cazHardQuestionsAttempts')
      localStorage.hardQuestionAttempts = 2
      localStorage.hardQWrongAnsweredQ = JSON.stringify([])
      localStorage.hardQuestionResultData = JSON.stringify(value.data)
    } else if (subProduct.category === 'caz_hard_questions') {
      localStorage.removeItem('hardQuestionAttempts')
      localStorage.cazHardQuestionsAttempts = 2
      localStorage.cazHardQWrongAnsweredQ = JSON.stringify([])
      localStorage.cazHardQuestionResultData = JSON.stringify(value.data)
    }
    else {
      localStorage.removeItem('hardQuestionAttempts')
    }


    try {
      dispatch({ type: "setQuestionnaire", qData: value.data })
      dispatch({
        type: "setQuizType",
        qType: type === 1 ? `scoala${ending}` : `atestat${ending}`,
      })
      dispatch({ type: "setStatus", status: "started" })
    } catch (e) {
      errorHandling(e.response.config.url, e.response.status, e.response.data.message)
    } finally {
      localStorage.setItem('qType', JSON.stringify({ type: item.quizType, qType: type === 1 ? `scoala${ending}` : `atestat${ending}`, }))
      item.quizType !== 1
        ? history.push("/app/certificate")
        : history.push("/app/chestionar-scoala")
    }
  }

  const openPopup = (e, product, type) => {
    setAlertMessage('Acest chestionar este o evaluare similara unui chestionar de examen. Se poate sustine un singur chestionar de acest tip la 24 ore')
    setOpen(true)
    setPassedPopup(true)
    localStorage.setItem('isExamPopup', true)
    localStorage.setItem('openQ', JSON.stringify({ product: product, type: type }))
  }

  const openQ = (e, product, type) => {
    localStorage.setItem('isChapter', false)
    localStorage.setItem('subProductId', product?.id)
    const qCreateData = new FormData()
    qCreateData.append("subproduct_id", product?.id)
    qCreateData.append("user_product_id", userProductId)
    qCreateData.append("questionnaire_type_id", JSON.parse(localStorage.lastProduct).obj.product_type)

    if (product.category === "learning") {
      localStorage.setItem('isExamPopup', false)
      casadiAPI
        .post("/user/product/chapters", qCreateData)
        .then((response) => {
          if (response.status === 200) {
            const questionnaire = response.data
            localStorage.setItem('questionnaire', JSON.stringify(questionnaire))
            history.push("/app/remote-learning", { questionnaire })
          }
        })
        .catch((e) => {
          errorHandling(e.response.config.url, e.response.status, e.response.data.message)
          setAlertMessage(e.response.data.message)
          setOpen(true)
        })
    } else {
      localStorage.removeItem('chapterId')
      localStorage.setItem('isChapter', false)
      casadiAPI
        .post('/questionnaire-create', qCreateData)
        .then((response) => {
          if (response.status === 200) {
            const questionnaire = response.data
            localStorage.setItem('questionnaire', JSON.stringify(questionnaire))
            localStorage.setItem('isExamPopup', false)
            storeQData(questionnaire, type, product)
          }
        })
        .catch((e) => {
          errorHandling(e.response.config.url, e.response.status, e.response.data.message)
          if (e.response.status === 403) {
            setAlertMessage('Acest chestionar este o evaluare similara unui chestionar de examen. Se poate sustine un singur chestionar de acest tip la 24 ore')
          } else {
            setAlertMessage(e.response.data.message)
          }

          if (localStorage.isExamPopup !== 'true') setOpen(true)
          localStorage.setItem('isExamPopup', false)
        })
    }
  }

  const clickToDescription = (str, e) => {
    setAlertMessage(str)
    setOpen(true)
    e.stopPropagation()
  }

  const checkSubProductChapter = (product) => {
    localStorage.setItem('subProductId', product?.id)
    casadiAPI
      .get(`/sub-product-chapters/${product?.id}/${userProductId}`)
      .then(({ data }) => {
        const questionnaire = data
        questionnaire.type = item.quizType
        localStorage.setItem('chapters', JSON.stringify(questionnaire))
        if (questionnaire.data.length) history.push("/app/remote-learning", { questionnaire })
        else {
          alert('Empty chapters')
          history.push("/")
        }
      })
      .catch((e) => {
        errorHandling(e.response.config.url, e.response.status, e.response.data.message)
        alert("Users Product dont find: " + e)
        history.push("/")
      })
  }

  const checkSubProductBooks = (product = null) => {
    casadiAPI
      .get(`/product-books/${JSON.parse(localStorage.lastProduct).value}`)
      .then(({ data }) => {
        setBooks(data)
      })
      .catch((e) => {
        alert("Users Product dont find: " + e)
      })
  }

  let getRemainingTime = (product) => {
    let sutka = 24 * 60 * 60 * 1000;
    let date = (product.passed_at || 0) * 1000 + sutka;
    let today = +new Date();
    let remaining = Math.round((date - today) / 1000 / 60);
    return remaining;
  }

  return (
    <MainContainer>
      <div className="cs-container">
        <Grid container spacing={10}>
          {loading ? (
            <div className={classes.loadingScreen}>
              <CircularProgress />
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              <h2>Subproduse</h2>
              <div
                style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
              >
                {subProduct?.map((product, index) => (
                  <Grow
                    key={index}
                    in={!loading}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(loading ? {} : { timeout: index * 200 })}
                  >
                    <Card className={classes.card} data-disabled={product.passed}>

                      <CardActionArea

                        onClick={(e) => {
                          setSelectedProduct(product)

                          if (product.category === "learning") {
                            if (!product.passed) {
                              openPopup(e, product, item.quizType)
                            } else {
                              checkSubProductChapter(product, item.quizType)
                            }
                          } else if (product.category === "hard_questions") {
                            openQ(e, product, item.quizType)
                          } else if (product.category === "exam" && !product.passed) {
                            openPopup(e, product, item.quizType)
                          } else {
                            openQ(e, product, item.quizType)
                          }
                        }}
                      >
                        {product.description && (<Typography className={classes.description} onClick={(e) => clickToDescription(product.description, e)}><CardMedia component="img" image={images.ic_question} alt="Question image" /></Typography>)}

                        <CardMedia
                          className={classes.media}
                          component="img"
                          alt="Product image"
                          height="140"
                          image={product.category === "exam" ? images.ic_invatare_partajata : images.ic_atestate}
                          title="Product image"
                        />
                        <CardContent>
                          <Typography className={classes.cardTitle} gutterBottom variant="h5" component="h2">
                            {product.name}
                          </Typography>
                          {
                            product.category === 'exam' && product.passed
                              ? <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                A ramas: <DisplayTimer timeLimitMinutes={getRemainingTime(product)} isShowHour={true} />
                              </span>
                              : ''
                          }
                          {
                            product.category === 'hard_questions' || product.category === 'caz_hard_questions'
                              ? <Typography gutterBottom variant="h6">Nr. Intrebari: {product.hard_questions || product.caz_hard_questions}</Typography>
                              : ''
                          }

                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                ))}
              </div>
              {
                !loading && (!subProduct || (subProduct && subProduct.length === 0)) ?
                  <div className={classes.emptySubproducts}>
                    <figure className={classes.emptyIconWrapper}>
                      <img className={classes.emptyIcon} src={images.engineering} alt="" />
                    </figure>
                    <span className={classes.emptyLabel}>Produsul este in curs de configurare</span>
                  </div>
                  : <></>
              }
              <h2>Invatare</h2>
              <div
                style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
              >
                {learnigProducts?.map((product, index) => (
                  <Grow
                    key={index}
                    in={!loading}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(loading ? {} : { timeout: index * 200 })}
                  >
                    <Card className={classes.card} data-disabled={product.passed}>
                      <CardActionArea
                        onClick={(e) => {
                          setSelectedProduct(product)

                          if (product.category === "learning") {
                            if (product.passed) {
                              setAlertMessage('The Learning Chapters was successfully passed!')
                              setOpen(true)
                            } else {
                              checkSubProductChapter(product, item.quizType)
                            }
                          } else {
                            openQ(e, product, item.quizType)
                          }
                        }}
                      >
                        <CardMedia
                          className={classes.media}
                          component="img"
                          alt="Product image"
                          height="140"
                          image={images.ic_learning}
                          title="Product image"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            {product.name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                ))}
                {books ? (
                  <Grow
                    in={!loading}
                    style={{ transformOrigin: "0 0 0" }}
                  >
                    <Card className={classes.card}>
                      <CardActionArea
                        onClick={(e) => {
                          setOpenBooks(true)
                        }}
                      >
                        <LibraryBooksIcon
                          className={classes.media}
                          height="140"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            Carti
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                ) : (<></>)}
              </div>
            </div>
          )}
        </Grid>
        <Dialog
          open={open}
          setOpen={setOpen}
          openQ={openQ}
          alertMessage={alertMessage}
          passedPopup={passedPopup}
          setPassedPopup={setPassedPopup}
        />
        <BooksModal
          openBooks={openBooks}
          setOpenBooks={setOpenBooks}
          books={books}
        />
      </div>
    </MainContainer>
  )
}

export default SubProducts
