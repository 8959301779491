import React from 'react';
import { Grid, Button } from '@material-ui/core';
import MainContainer from '../../components/MainContainer';
import { useContextValue } from '../../state/context';
import { useHistory } from 'react-router-dom';

const Profile = () => {
  // eslint-disable-next-line
  const [ctxt = {}, dispatch] = useContextValue(); 
  const history = useHistory();

  const logOut = () => {
    localStorage.removeItem('@user');
    dispatch({ type: 'removeUser' });
    history.push('/');
  };

  return (
    <MainContainer
      contentStyle={{
        marginRight: 185,
        marginLeft: 185,
        marginTop: 100,
        marginBottom: 185,
        minHeight: '50vh',
      }}
    >
      <Grid container>
        <Button
          variant="contained"
          color="secondary"
          style={{ width: 180, height: 67 }}
          onClick={() => logOut()}
        >
          Iesi din cont
        </Button>
      </Grid>
    </MainContainer>
  );
};

export default Profile;
