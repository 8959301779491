import React, { useState } from 'react';
import {
  Card,
  Grow,
  Typography,
  TextField,
  Container,  
  CardActionArea,
  CardContent,
  CardMedia,
  InputAdornment,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { images } from "../../resources"
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MainContainer from '../../components/MainContainer';
import ForwardButton from '../../components/ForwardButton';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import casadiAPI from '../../utils/api';
import { errorHandling } from "../../utils/api"
import { useContextValue } from '../../state/context';
const useStyles = makeStyles(() => ({
    card: {
      width: "210px",
      height: "210px",
      marginRight: "30px",
      marginBottom: "30px",
      fontSize:"1.5rem;",
      textAlign:"center",
      justifyContent:"center",
      display:"flex",
      alignItems:"center",
      flexDirection:"column-reverse",
    },
    grow: {
        transform:"scale(1) !important",
        opacity: "1 !important",
        visibility: "visible !important",
        width:"210px !important",
        height:"210px !important"
    },
    center: {
        margin: "0 auto",
        display:"flex",
        justifyContent:"center",
        height:"66vh",
        gap:"30px",
    },
    media: {
        marginTop: "10px",
        height: "100px",
        width: "auto",
        marginLeft: "auto",
        marginRight: "auto",
      },
  }))

const Settings = () => {
    
    const history = useHistory();
    const classes = useStyles();

    const clickToResetPassword = () => {
        history.push('/app/change-password')
    }
    return (
        <MainContainer >
        <Container className={classes.center}>
            <Grow className={classes.grow}  style={{ transformOrigin: "0 0 0" }}>
                <CardActionArea>
                <Card className={classes.card} onClick={() => clickToResetPassword()}>Schimbă parola                <CardMedia
                          className={classes.media}
                          component="img"
                          alt="Product image"
                          height="140"
                          image={ images.ic_invatare_partajata}
                          title="Product image"
                        /></Card>

                </CardActionArea>
            </Grow>

        </Container>
    </MainContainer>
    )

}
export default Settings;