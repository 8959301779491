import React from "react";
import {
    Typography,
    Grid,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import MainContainer from "../../components/MainContainer";
import ForwardButton from "../../components/ForwardButton";
import BlueTriangle from "../../components/BlueTriangle";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
    },
    title: {
        fontSize: 18,
        margin: "30px 0",
    },
}));

const ConfirmMatriculation = () => {
    const classes = useStyles();

    return (
        <MainContainer contentStyle={{ margin: "125px 185px" }}>
            <Grid container>
                <Grid item xs={12} md={4} style={{ textAlign: "left" }}>
                    <Typography className={classes.title} color="secondary">
                        Alegi să te înscrii pentru Școala de șoferi, Categoria B
                    </Typography>
                    <div style={{ marginBottom: 30, width: "100%" }}>
                        <Card>
                            <CardContent>
                                <List>
                                    <ListItem>
                                        <Typography color="secondary" style={{ fontWeight: 700 }}>
                                            Conținutul pachetului/produsului:
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            color="secondary"
                                            primary="- Legislație rutieră;"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText color="secondary" primary=" - Mecanică;" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText color="secondary" primary=" - Chestionare;" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText color="secondary" primary="- Evaluare;" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText color="secondary" primary="- Examen." />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </div>

                    <Link style={{ textDecoration: "none" }} to="/app/scoala-date">
                        <ForwardButton width="100%">Confirmă înscrierea</ForwardButton>
                    </Link>
                </Grid>

                <Grid item xs={12} md={2}></Grid>

                <Grid item xs={12} md={2}>
                    <BlueTriangle />
                </Grid>
            </Grid>
        </MainContainer>
    );
};

export default ConfirmMatriculation;
