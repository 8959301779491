import React, { useState } from 'react';
import MainContainer from '../../components/MainContainer';
import { 
  Container, 
  Typography, 
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';
import casadiAPI, { errorHandling } from '../../utils/api';
import { useContextValue } from '../../state/context';
import ChapterMaterialsModal from '../../components/ChaptersModal'

const useStyles = makeStyles(() => ({
  media: {
    marginTop: '10px',
    height: '100px',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loadingScreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    flexDirection: 'column',
    height: '100%',
  },
  container: {
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    width: '100%',
    backgroundColor: 'white',
    border: '0.5px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 81,
  },
  cardElement: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentWrapper: {
    marginTop: 30,
    textAlign: 'left',
  },
  questionNumber: {
    color: '#00415E',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 18,
  },
  question: {
    marginTop: 10,
    fontSize: 20,
    color: '#343043',
    fontWeight: 700,
  },
  answerCardWrapper: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 800,
    maxWidth: 800,
    width: 800,
    // maxWidth: 800,
  },
  answerCard: {
    height: 83,
    margin: 8,
    paddingRight: 25,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  correctAnswer: {
    height: 83,
    margin: 8,
    paddingRight: 25,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#C8FFD7',
  },
  wrongAnswer: {
    height: 83,
    margin: 8,
    paddingRight: 25,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#FFD6DA',
  },
  selectedAnswerMarker: {
    backgroundColor: '#D1EDFF',
    height: '100%',
    minWidth: 100,
    fontWeight: 700,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#00415E',
  },
  answerMarker: {
    backgroundColor: '#F2F2F2',
    height: '100%',
    minWidth: 100,
    color: '#828282',
    fontWeight: 700,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  correctAnswerMarker: {
    backgroundColor: '#C8FFD7',
    height: '100%',
    minWidth: 100,
    color: '#00415E',
    fontWeight: 700,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrongAnswerMarker: {
    backgroundColor: '#FFD6DA',
    height: '100%',
    minWidth: 100,
    color: '#00415E',
    fontWeight: 700,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  answerOptionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 800,
  },
  answerOptionsRetrospective: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  answerWrp: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  answer: {
    marginLeft: 25,
    paddingRight: 15,
    color: '#828282',
    fontSize: 18,
    textAlign: 'left',
  },
  checkIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const RemoteLearning = () => {
  const classes = useStyles();
  const { state } = useLocation();
  // eslint-disable-next-line
  const [{}, dispatch] = useContextValue();
  const { questionnaire } = state;
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepChapterId, setActiveStepChapterId] = useState(0);
  const [isActiveTestBtn, setIsActiveTestBtn] = useState(true);
  const history = useHistory()
  const [open, setOpen] = useState(false);
  const [isLector, setIsLector] = useState(false)

  const userProductId = localStorage.userProductId || null
  const subProductId = localStorage.subProductId || null

  const getActiveChapter = () => {
    const chapters = questionnaire.data

    // if user role includes lector, we open all chapters
    if (isLector) {
      setActiveStep(chapters.length - 1)
    } else {
      for (var i = chapters.length - 2; i >= 0; i--) {
        if (chapters[i]?.passed) {
          setActiveStep(i + 1)
          localStorage.setItem('chapterId', chapters[i + 1].id)
          break
        } else {
          localStorage.setItem('chapterId', chapters[0].id)
        }
      }

      isFinishedChapters(chapters.length - 1) && setActiveStep(chapters.length)
    }
  } 

  const isFinishedChapters = index => {
    return (questionnaire.data[index].passed)
  }

  const openQ = (id) => {
    localStorage.setItem('isChapter', true)
    
    const qCreateData = new FormData()
    qCreateData.append("subproduct_id", subProductId)
    qCreateData.append("user_product_id", userProductId)
    qCreateData.append("questionnaire_type_id", 1)
    qCreateData.append("chapter_id", id)

    casadiAPI
      .post('/questionnaire-create', qCreateData)
      .then((response) => {
        if (response.status === 200) {
          const questionnaire = response.data
          localStorage.setItem('questionnaire', JSON.stringify(questionnaire))
          storeQData(questionnaire, 1)
        }
      })
      .catch((e) => {
        errorHandling(e.response.config.url, e.response.status, e.response.data.message)
        alert(e.response.data)
      })
  }

  const storeQData = async (value) => {
    try {
      dispatch({ type: "setQuestionnaire", qData: value.data })
      dispatch({
        type: "setQuizType",
        qType: questionnaire.type === 1 ? `scoalaSQ` : `atestatSQ`,
      })
      dispatch({ type: "setStatus", status: "started" })
    } catch (e) {
    } finally {
      localStorage.setItem('qType', JSON.stringify({type: questionnaire.type, qType: questionnaire.type === 1 ? `scoalaSQ` : `atestatSQ`}))
      questionnaire.type !== 1
        ? history.push("/app/certificate")
        : history.push("/app/chestionar-scoala")
    }
  }
  localStorage.setItem('isChapter', true)
  React.useEffect(() => {
    getActiveChapter()
    if (localStorage.openMaterials === 'true') setOpen(true)

    // if user role includes lector, we open all chapters
    JSON.parse(localStorage.getItem('@user')).user.user_roles && JSON.parse(localStorage.getItem('@user')).user.user_roles.includes('lector') ?
      setIsLector(true) :
      setIsLector(false)
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    setActiveStepChapterId(questionnaire.data[activeStep]?.id)
    // eslint-disable-next-line
  } ,[activeStep])

  return (
    <MainContainer>
      <div className='cs-container'>
        <Container className={classes.contentWrapper}>
          <Typography color="primary" className={classes.question}>
            Invatare partajata
          </Typography>

          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {questionnaire.data.map((chapter, index) => (
                <Step key={index}>
                  <StepLabel
                    onClick={ () => { 
                      if (activeStep > index) {
                        setActiveStepChapterId(chapter.id)
                        setIsActiveTestBtn(false)
                        setOpen(true) 
                      }
                    }}
                  >
                    {chapter.name}
                  </StepLabel>
                  <StepContent
                    active={isLector || index === activeStep}
                  >
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          style={{ marginRight: '10px' }}
                          onClick={ () => { setIsActiveTestBtn(true); setOpen(true) } }
                        >
                          Materiale de studiu
                        </Button>
                        {(questionnaire.data[activeStep]?.test_status) || isLector ? (
                          <Button
                            variant="contained"
                            onClick={ () => {
                              localStorage.setItem('isChapter', true)
                              openQ(chapter.id)
                              index === questionnaire.data.length - 1 ?
                                localStorage.setItem('lastChapter', true) :
                                localStorage.setItem('lastChapter', false)
                            } }
                            sx={{ mt: 1, mr: 1 }}
                          >
                            {index === questionnaire.data.length - 1 ? 'Finish' : 'Test'}
                          </Button>
                        ) : (
                          <Button disabled>Test</Button>
                        )}
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === questionnaire.data.length && (
              <Paper square elevation={0} style={{ padding: 24 }}>
                <Typography>Felicitari, ati finalizat Invatarea partajata! Mult succes in continuare!</Typography>
              </Paper>
            )}
          </Box>
          <ChapterMaterialsModal
            setOpen={setOpen}
            open={open}
            id={activeStepChapterId}
            isActiveTestBtn={isActiveTestBtn}
            testStatus={questionnaire.data[activeStep]?.test_status}
          />
        </Container>
      </div>
  </MainContainer>
  );
};
export default RemoteLearning;
