import React from "react";
import { Button } from "@material-ui/core";

import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

const ForwardButton = (props) => {
    return (
        <Button
            color="primary"
            variant="contained"
            style={{ width: props.width ? props.width : 230, height: 65, borderRadius: 4 }}
            startIcon={<DoubleArrowIcon />}
            {...props}
        >
            {props.children}
        </Button>
    );
};

export default ForwardButton;
