import { createMuiTheme } from "@material-ui/core";

const CasadiLightTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#D23243", //red casadi
            // dark: "#00415E", //bleumarin 2
            light: "#4282AB", //bleumarin 1/
        },
        text: {
            // primary: "#fff",
            secondary: "#999",
        },
        secondary: {
            main: "#00415E", //bleumarin 2
            // contrastText: "#720a0a",
        },
        // background: {
        //   paper: "#424242",
        //   default: "#303030"
        // }
        success: {
            main: "#6AD688",
        },
    },
});

export default CasadiLightTheme;
