import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import casadiAPI from '../../utils/api';
import { errorHandling } from "../../utils/api"
import { useHistory, Link } from 'react-router-dom';
import MainContainer from '../../components/MainContainer';
import ForwardButton from '../../components/ForwardButton';
import BlueTriangle from '../../components/BlueTriangle';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
}));

const ChooseProduct = () => {
  const classes = useStyles();
  const history = useHistory();
  const [userProducts, setUserProducts] = useState([]);
  const [selection, setSelection] = useState('');
  const [selectedQuiz, setSelectedQuiz] = useState('');

  //Temporary selection handling
  const handleChange = (e) => {
    setSelection(e.target.value);
  };

  const handleSelectedQuiz = (e) => {
    setSelectedQuiz(e.target.value.obj.id);
    openQ(selection, e.target.value);
  };

  //Temporary handling
  const openQ = (type, screen) => {
    localStorage.setItem('isChapter', false)
    const qCreateData = new FormData();

    qCreateData.append('product_id', screen.obj.id);
    qCreateData.append('questionnaire_type_id', type === 'scoala' ? 1 : 2);

    casadiAPI
      .post('/questionnaire-create', qCreateData)
      .then((response) => {
        if (response.status === 200) {
          const questionnaire = response.data.data;
          history.push(`/app/chestionar-scoala/`, {
            questionnaire,
            quizData: {
              screen: `${selection}SQ`, //here there should me some selection for SQ/TQ
              selectedProduct: screen.obj.id,
              type: type === 'scoala' ? 1 : 2,
              qName: screen.obj.id,
            },
          });
        }
      })
      .catch((err) => {
        errorHandling(err.response.config.url, err.response.status, err.response.data.message)
      });
  };

  useEffect(() => {
    casadiAPI
      .get('/product')
      .then((response) => {
        if (response.status === 200) {
          const products = [...response.data.data];

          const options = products.map((obj, index) => {
            if (obj.questionnaire_length) {
              return {
                label: `${obj.name}`,
                value: obj.id,
                quizType: obj.product_type.name,
                obj: obj,
              };
            } else return false
          });
          setUserProducts(options);
        }
      })
      .catch((err) => {
        errorHandling(err.response.config.url, err.response.status, err.response.data.message)
      });
  }, []);

  return (
    <MainContainer contentStyle={{ margin: 185 }}>
      <Grid container>
        <Grid item xs={12} md={4} style={{ textAlign: 'left' }}>
          <Typography
            style={{ fontSize: 18, margin: '30px 0' }}
            color="secondary"
          >
            Selectează tipul produsului și categoria dintre opțiunile:
          </Typography>
          <div style={{ marginBottom: 30 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selection}
                onChange={handleChange}
              >
                {/* <MenuItem value="">
                                    <em>None</em>
                                </MenuItem> */}
                <MenuItem value={'scoala'}>Școala de șoferi</MenuItem>
                <MenuItem value={'atestat'}>Atestare</MenuItem>
                {/* <MenuItem value={30}>Trei</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div style={{ marginBottom: 30 }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedQuiz}
                onChange={(e) => handleSelectedQuiz(e)}
              >
                {/* <MenuItem value="">
                                    <em>None</em>
                                </MenuItem> */}
                {/* <MenuItem value={10}>Categorie B</MenuItem>
                <MenuItem value={20}>Doi</MenuItem>
                <MenuItem value={30}>Trei</MenuItem> */}
                {userProducts
                  .filter((product) => product.quizType === selection)
                  .map((product) => (
                    <MenuItem value={product} key={product.id}>
                      {product.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <Link
            style={{ textDecoration: 'none' }}
            to="/app/confirmare-inscriere"
          >
            <ForwardButton width="100%">Înscrie-te acum</ForwardButton>
          </Link>
        </Grid>

        <Grid item xs={12} md={2}></Grid>

        <Grid item xs={12} md={2}>
          <BlueTriangle />
        </Grid>
      </Grid>
    </MainContainer>
  );
};

export default ChooseProduct;
