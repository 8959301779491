import React from 'react';
import { Card, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 20,
  },
  card: {
    width: '31.9px',
    height: '31.9px',
    borderRadius: 0,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 3,
    marginBottom: 3,
  },
  cardNumber: {
    font: 'Oxygen',
    fontWeight: 700,
    fontSize: 14,
  },
}));

const QuestionNav = ({
  questions,
  currentQuestion,
  setCurrent,
  current,
  qType,
  page,
  setShowAnswers
}) => {
  const classes = useStyles();

  const getBackgroundColor = (item, index) => {
    if (item.answered) {
      if (item.status) {
        return '#C8FFD7';
      } else {
        return '#FFD6DA';
      }
    } else {
      return '#F2F2F2';
    }
  };

  const getFontColor = (item, index) => {
    if (item.answered || currentQuestion === index) {
      return '#00415E';
    } else {
      return '#828282';
    }
  };

  const goToQuestion = (e, question, index) => {
    setCurrent(index)
    setShowAnswers(false)
  };

  const lastIndex = () => {
    return questions.length
  }
  const firstIndex = () => {
    return questions.length
  }

  return (
    <Container key={page} className={classes.container}>
      {questions
        .map((item, index) => (
          <Card
            className={classes.card}
            style={{
              backgroundColor: getBackgroundColor(item, index),
              color: getFontColor(item, index),
            }}
            key={index}
            onClick={(e) => goToQuestion(e, item, index)}
          >
            <Typography className={classes.cardNumber}>
              {page > 1 ? (page - 1) * 20 + index + 1 : index + 1}
            </Typography>
          </Card>
        ))
        .slice(firstIndex, lastIndex())}
    </Container>
  );
};

export default QuestionNav;
