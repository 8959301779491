import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Typography,
  TextField,
  Container,
  FormControl,
  Select,
  MenuItem,
  Button,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
// import { useForm } from "react-hook-form";

import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';

import MainContainer from '../../components/MainContainer';

import casadiAPI from '../../utils/api';
import { useContextValue } from '../../state/context';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    marginBottom: 30,
  },
  input: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    textAlign: 'left',
  },
  lightText: {
    fontSize: 18,
    color: '#CECECE',
    marginBottom: 45,
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    color: '#615F69',
  },
  sectionTitle: {
    textAlign: 'left',
    color: '#4282AB',
    fontWeight: 700,
    fontSize: 24,
    marginBottom: 15,
  },
}));

//TODO: integrate react-hook-form
const PersonalData = () => {
  const classes = useStyles();
  const [{ user }] = useContextValue();
  // const { register, handleSubmit, watch, errors } = useForm();
  ///data
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [CNP, setCNP] = useState('');

  //domiciliu
  const [county, setCounty] = useState('1');
  const [city, setCity] = useState('1');
  const [street, setStreet] = useState('');
  const [streetNr, setStreetNr] = useState('');

  //certificat
  const [certBirth, setCertBirth] = useState(new Date());
  const [certCountry, setCertCountry] = useState('');
  const [certCounty, setCertCounty] = useState('1');
  const [certCity, setCertCity] = useState('1');
  const [certNationality, setCertNationality] = useState('1');

  //act
  const [actSeries, setActSeries] = useState('');
  const [actNumber, setActNumber] = useState('');
  const [actDate, setActDate] = useState(new Date());
  const [actIssuer, setActIssuer] = useState('1');

  //permis
  // const [driverLicense, setDriverLicense] = useState('1');

  //informatii
  // const [studiedAt, setStudiedAt] = useState('1');
  // const [examAt, setExamAt] = useState('1');
  // const [reason, setReason] = useState('1');

  const [showSnackbar, setShowSnackbar] = useState(false);

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const checkForm = () => {
    const formData = {
      firstname: firstName,
      lastname: lastName,
      cnp: CNP,

      residence_county: county,
      residence_city: city,
      residence_street: street,
      residence_number: streetNr,

      // birth_date: certBirth,
      birth_country: certCountry,
      birth_county: certCounty,
      birth_city: certCity,
      birth_nationality: certNationality,

      identity_series: actSeries,
      identity_number: actNumber,
      // identity_date: actDate,
      identity_released_by: actIssuer,

      // driverLicense,

      // studiedAt,
      // examAt,
      // reason,
    };


    casadiAPI.put('/user/' + user.id, formData).then((response) => {

      if (response.status === 200) {
        const user = response.data.data;
        setShowSnackbar(true);

        // dispatch({ type: "setUser", user });
        // history.push("/");
      }
    });
    // .catch(errorState);
  };

  return (
    <MainContainer contentStyle={{ margin: '80px 35%' }}>
      <Container direction="row" justify="center">
        <Typography className={classes.lightText}>
          ACHIZIȚIONARE PRODUS
        </Typography>
        <Typography color="primary" className={classes.title}>
          Date personale
        </Typography>
        <Typography className={classes.subtitle}>
          Completează datele necesare înregistrării pentru categoria/categoriile
          alese
        </Typography>

        <div style={{ marginTop: 55 }}>
          <div className={classes.inputContainer}>
            <TextField
              className={classes.input}
              color="secondary"
              variant="outlined"
              label="Nume"
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              className={classes.input}
              color="secondary"
              variant="outlined"
              label="Prenume"
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              className={classes.input}
              color="secondary"
              variant="outlined"
              label="CNP"
              onChange={(event) => setCNP(event.target.value)}
              value={CNP}
            />
          </div>
        </div>

        <div style={{ marginTop: 55 }}>
          <Typography className={classes.sectionTitle}>Domiciliu</Typography>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={county}
                onChange={(event) => setCounty(event.target.value)}
              >
                <MenuItem value="1">Județ (sector)</MenuItem>
                <MenuItem value="Cluj">Cluj</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={city}
                onChange={(event) => setCity(event.target.value)}
              >
                <MenuItem value="1">Localitate</MenuItem>
                <MenuItem value="Cluj-Napoca">Cluj-Napoca</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.inputContainer}>
            <TextField
              style={{ width: '55%', marginRight: '5%' }}
              color="secondary"
              variant="outlined"
              label="Strada"
              onChange={(event) => setStreet(event.target.value)}
              value={street}
            />
            <TextField
              style={{ width: '40%' }}
              color="secondary"
              variant="outlined"
              label="Număr"
              onChange={(event) => setStreetNr(event.target.value)}
              value={streetNr}
            />
          </div>
        </div>

        <div style={{ marginTop: 55 }}>
          <Typography className={classes.sectionTitle}>
            Certificat de naștere
          </Typography>
          <div className={classes.inputContainer}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                label="Data nașterii"
                // helperText="No year selection"
                style={{ width: '100%' }}
                color="secondary"
                value={certBirth}
                onChange={setCertBirth}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.inputContainer}>
            <TextField
              className={classes.input}
              color="secondary"
              variant="outlined"
              label="Țara de origine"
              onChange={(event) => setCertCountry(event.target.value)}
              value={certCountry}
            />
          </div>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={certCounty}
                onChange={(event) => setCertCounty(event.target.value)}
              >
                <MenuItem value="1">Județ (sector)</MenuItem>
                <MenuItem value="Cluj">Cluj</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={certCity}
                onChange={(event) => setCertCity(event.target.value)}
              >
                <MenuItem value="1">Localitate</MenuItem>
                <MenuItem value="Cluj-Napoca">Cluj-Napoca</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={certNationality}
                onChange={(event) => setCertNationality(event.target.value)}
              >
                <MenuItem value="1">Naționalitate</MenuItem>
                <MenuItem value="romanian">Roman</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ marginTop: 55 }}>
          <Typography className={classes.sectionTitle}>
            Act de identitate
          </Typography>
          <div className={classes.inputContainer}>
            <TextField
              style={{ width: '30%', marginRight: '5%' }}
              color="secondary"
              variant="outlined"
              label="Seria"
              onChange={(event) => setActSeries(event.target.value)}
              value={actSeries}
            />
            <TextField
              style={{ width: '65%' }}
              color="secondary"
              variant="outlined"
              label="Număr"
              onChange={(event) => setActNumber(event.target.value)}
              value={actNumber}
            />
          </div>
          <div className={classes.inputContainer}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                label="Data eliberării"
                // helperText="No year selection"
                style={{ width: '100%' }}
                color="secondary"
                value={actDate}
                onChange={setActDate}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={actIssuer}
                onChange={(event) => setActIssuer(event.target.value)}
              >
                <MenuItem value="1">Eliberat de</MenuItem>
                <MenuItem value="SPCLEP-CLUJ">SPCLEP Cluj</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ marginTop: 55 }}>
          <Typography className={classes.sectionTitle}>
            Permis de conducere
          </Typography>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={10}
                // onChange={handleChange}
              >
                <MenuItem value={10}>Nu dețin permis de conducere</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ marginTop: 55 }}>
          <Typography className={classes.sectionTitle}>
            Informații înscriere
          </Typography>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={10}
                // onChange={handleChange}
              >
                <MenuItem value={10}>Pregătire susținută la...</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={10}
                // onChange={handleChange}
              >
                <MenuItem value={10}>Examen susținut la...</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classes.inputContainer}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={10}
                // onChange={handleChange}
              >
                <MenuItem value={10}>Motivul înscrierii </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div style={{ marginTop: 55 }}>
          <Link
            style={{ textDecoration: 'none' }}
            to="/app/incarcare-documente"
          >
            <Button
              variant="contained"
              color="secondary"
              style={{ width: 180, height: 67 }}
            >
              CONTINUĂ
            </Button>
          </Link>
          <Button
            variant="contained"
            color="secondary"
            style={{ width: 180, height: 67, marginLeft: 20 }}
            onClick={checkForm}
          >
            ActualizeazĂ date
          </Button>
        </div>
      </Container>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="success">
          Datele au fost actualizate
        </Alert>
      </Snackbar>
    </MainContainer>
  );
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default PersonalData;
