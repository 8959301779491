import React, { useState, useEffect } from 'react';
import MainContainer from '../../components/MainContainer';
import { Container, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useContextValue } from '../../state/context';
import Question from '../../components/questionnaire/Question';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  sucessText: {
    color: '#6AD688',
    fontSize: 22,
    lineHeight: 2,
    textAlign: 'center',
    letterSpacing: 0.15,
    fontWeight: 'bold',
  },
  header: {
    color: '#D23243',
    fontSize: 40,
    lineHeight: 2,
    letterSpacing: 0.15,
    fontWeight: '700',
  },
  subHeader: {
    fontWeight: 'bold',
    color: '#00415E',
    lineHeight: 2,
    letterSpacing: 0.15,
    fontSize: 22,
    textAlign: 'center',
    marginBottom: 40,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    margin: '20px auto',
    marginTop: 100,
  },
  time: {
    fontWeight: 'bold',
    color: '#00415E',
    lineHeight: 2,
    letterSpacing: 0.15,
    fontSize: 20,
  },
  timeData: {
    margin: ' 0px auto',
    display: 'flex',
    alignItems: 'center',
  },
  answerContainer: {
    width: '80%',
    backgroundColor: '#fff',
    padding: '30px 20px',
    marginBottom: 25,
    overflow: 'hidden',
  },
  answers: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  reportText: {
    marginLeft: 4,
    cursor: 'pointer',
    color: '#2D9CDB',
    fontWeight: 700,
  },
  explanationContainer: {
    marginTop: 20,
  },
  explanationHeader: {
    fontFamily: 'Oxygen',
    display: 'flex',
    textTransform: 'uppercase',
    fontSize: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  explanationNr: {
    fontFamily: 'Oxygen',
    fontWeight: 700,
    alignSelf: 'center',
    marginLeft: 5,
    fontSize: 20,
  },
  explanationText: {
    fontFamily: 'Oxygen',
    textAlign: 'left',
    color: '#615F69',
    fontSize: 18,
    marginTop: 20,
  },
  startNew: {
    marginTop: 20,
  },
}));

const Retrospective = () => {
  const classes = useStyles();
  const history = useHistory();
  const [{ qData, qType }] = useContextValue();
  const [showExplanation, setExplanation] = useState('');
  const [selected, setSelected] = useState(
    qData.questions.map((item) => item.selected).flat(1)
  );
  const [loaded, setLoaded] = useState(false)
  const [retrospectiveType, setRetrospectiveType] = useState('')

  const ActionButton = withStyles({
    root: {
      borderRadius: 50,
      height: 40,
      transform: 'scale(1.3)',
      borderColor: '#4282AB',
      color: '#4282AB',
      filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14))',
    },
  })((props) => <Button color="default" style={{ fontSize: 12 }} {...props} />);

  const startNew = () => {
    history.push('/app/products');
  };

  const setShowAnswers = () => {};

  const handleExplanation = (index) => {
    if (index !== showExplanation) {
      setExplanation(index);
    } else {
      setExplanation('');
    }
  };

  useEffect(() => {
    if (!loaded) {
      if (qType === 'scoalaTQ' || qType === 'scoalaSQ') {
        setRetrospectiveType('scoalaSQ')
      } else {
        setRetrospectiveType('atestatSQ')
      }
    } else {
      setLoaded(false)
    }
  }, [loaded, qType])

  return (
    <MainContainer
      contentStyle={{
        marginRight: 185,
        marginLeft: 185,
        marginTop: 100,
        marginBottom: 100,
        minHeight: '50vh',
      }}
    >
      <Container className={classes.container}>
        <div className={classes.header}>Retrospectivă</div>
        <div className={classes.subHeader}>Verifică răspunsurile tale</div>
      </Container>
      <div className={classes.answers}>
        {qData.questions.map((question, index) => (
          <Container className={classes.answerContainer}>
            <Question
              mode={'retrospective'}
              question={question}
              questionNumber={index}
              selected={selected}
              setSelected={setSelected}
              showAnswers={true}
              setShowAnswers={setShowAnswers}
              qType={retrospectiveType} //TODO: fix this
            />

            {qData.questions[index].explanation && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 15,
                  alignItems: 'center',
                }}
              >
                <InfoIcon style={{ color: ' #2D9CDB' }} />
                <div
                  className={classes.reportText}
                  onClick={() => handleExplanation(index)}
                >
                  Explicație
                </div>
                {showExplanation ? (
                  <ExpandLessIcon style={{ color: ' #2D9CDB' }} />
                ) : (
                  <ExpandMoreIcon style={{ color: ' #2D9CDB' }} />
                )}
              </div>
            )}
            {showExplanation === index && (
              <Container className={classes.explanationContainer}>
                <div style={{ display: 'flex', marginBottom: 15 }}>
                  <div className={classes.explanationHeader}>
                    EXPLICAȚIE ÎNTREBARE -
                  </div>
                  <div className={classes.explanationNr}>
                    Răspuns corect{' '}
                    {(
                      qData.questions[index].answers.findIndex((x) => x.valid) +
                      10
                    )
                      .toString(36)
                      .toUpperCase()}
                  </div>
                </div>

                <div className={classes.explanationText}>
                  {qData.questions[index].explanation}
                </div>
              </Container>
            )}
          </Container>
        ))}
      </div>
      <ActionButton
        className={classes.startNew}
        variant="outlined"
        onClick={startNew}
      >
        ÎNCEPE ALT CHESTIONAR
      </ActionButton>
    </MainContainer>
  );
};

export default Retrospective;
