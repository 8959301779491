import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  useHistory,
  Switch,
  Route,
} from 'react-router-dom';
import Landing from '../Landing';

import Register from '../Register';
import Login from '../Login';
import ResetPassword from '../ResetPassword';

import AppRoutes from '../AppRoutes';
import NotFound from '../NotFound';

import { useContextValue } from '../../state/context';

const RouteProvider = () => {
  const [{ user }, dispatch] = useContextValue();
  const history = useHistory();

  useEffect(() => {
    const getUserFromDevice = async () => {
      let storedUser;
      try {
        storedUser = await localStorage.getItem('@user');
      } catch (e) {
      }
      if (storedUser || (user && user.id)) {
        const data = JSON.parse(storedUser);
        dispatch({ type: 'setUser', user: data.user });
        // history.push('/app/products');
      } else {
        history.push('/');
        return <Login />;
      }
    };
    getUserFromDevice();
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login}>
          <Login />
        </Route>
        <Route path="/home">
          <Landing />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/app">
          <AppRoutes />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};

export default RouteProvider;
