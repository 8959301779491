import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Backdrop from "@material-ui/core/Backdrop";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { useHistory } from 'react-router-dom';
import Link from "@material-ui/core/Link"

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    width: 600,
    height: 343,
  },
  header: {
    display: "flex",
    alignItems: "center",
    font: "Oxygen",
    color: "#00415E",
    fontSize: 18,
    lineHeight: 2.2,
  },
  options: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 80,
  },
  reportButton: {
    display: "flex",
    justifyContent: "center",
  },
  customReport: {
    display: "flex",
    alignItems: "center",
  },
  inputLabel: {
    color: "black",
    marginLeft: 0,
  },
}));

const BooksModal = ({ openBooks, setOpenBooks, books }) => {
  const classes = useStyles();
  const history = useHistory()

  const handleClose = () => {
    setOpenBooks(false);
  }

  // const downloadBooks = (uuid, name) => {
  //   casadiAPI
  //       .get(`/download-material/${uuid}`)
  //       .then((response) => {
  //           if (response.status === 200) {
  //               const url = window.URL.createObjectURL(new Blob([response.data]))
  //               const link = document.createElement('a')
  //               link.href = url
  //               link.setAttribute('download', name)
  //               document.body.appendChild(link)
  //               link.click()
  //           }
  //       })
  //       .catch((e) => {
  //           alert(e.response.data)
  //       })
  //   }

  const openChapterMaterial = link => {
    history.push("/app/file", link)
}

  return (
    <div>
      <Dialog
        aria-labelledby="transition-modal-title"
        className={classes.modal}
        open={openBooks}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <DialogContent>
            <div className="material-detail">
                <h2 id="transition-modal-title" className={classes.header}>
                <MenuBookIcon
                    style={{ fontSize: 36, color: "#333", marginRight: 20 }}
                />
                    Carti:
                </h2>
                <ol>
                    { books && books.map((item, index) => (
                        <li key={ index }>
                            { item.name ? (
                                <Link
                                    className="material-link"
                                    onClick={ () => openChapterMaterial(item.url) }
                                >
                                    { item.name }
                                </Link>
                            ) : (
                                <></>
                            ) }
                        </li>
                    )) }
                </ol>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={ () => setOpenBooks(false) }>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BooksModal;
