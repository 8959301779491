import React from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import MainContainer from '../../components/MainContainer';
import ForwardButton from '../../components/ForwardButton';
import BlueTriangle from '../../components/BlueTriangle'; 

const items = [
  {
    name: 'Categoria B',
    description: 'Lorem Ipsum sit dolor amet',
  },
  {
    name: 'Categoria D',
    description: 'Lorem Ipsum sit dolor amet',
  },
];

const useStyles = makeStyles((theme) => ({
  carousel: {
    width: 270,
    zIndex: 10,
  },
}));

const Landing = () => {
  const classes = useStyles();

  return (
    <MainContainer contentStyle={{ margin: 185 }}>
      <Grid container>
        <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
          <Typography
            style={{
              fontSize: 67,
              fontWeight: 'bold',
              letterSpacing: 0.4,
            }}
            color="primary"
          >
            Cursuri de pregătire și perfecționare auto
          </Typography>
          <Typography
            style={{ fontSize: 30, margin: '45px 0', color: '#615F69' }}
          >
            Învață online simplu și eficient pentru școala de șoferi sau
            atestare rutieră
          </Typography>
          <Link style={{ textDecoration: 'none' }} to="/app/alege-produs">
            <ForwardButton>Înscrie-te acum</ForwardButton>
          </Link>
        </Grid>

        <Grid item xs={12} md={2}></Grid>

        <Grid item xs={12} md={2}>
          <Carousel
            animation="slide"
            autoPlay={false}
            className={classes.carousel}
          >
            {items.map((item, i) => (
              <Item key={i} item={item} />
            ))}
          </Carousel>
          <BlueTriangle />
        </Grid>
      </Grid>
    </MainContainer>
  );
};

function Item(props) {
  return (
    <Card
      style={{
        backgroundColor: '#fff',
        boxShadow: '0px 0px 50px 3px rgba(0, 0, 0, 0.15)',
        height: 390,
        borderRadius: 10,
      }}
    >
      <CardContent>
        <img
          src="https://picsum.photos/150"
          style={{ borderRadius: 100 }}
          alt=""
        ></img>
        <h2>{props.item.name}</h2>
        <p>{props.item.description}</p>
      </CardContent>
    </Card>
  );
}

export default Landing;
