const images = {
  ic_category_icon: require('./../images/logo_categoria_b.png'),
  ic_atestate: require('./../images/atestate.png'),
  ic_scoala: require('./../images/scoala.png'),
  ic_invatare_partajata: require('./../images/invatare.png'),
  ic_learning: require('./../images/elearning.png'),
  ic_question: require('./../images/question-mark.png'),
  engineering: require('./../images/engineering.png'),
}

export default images