import React from 'react';

import { Grid, Typography } from '@material-ui/core';

// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   link: {
//     display: 'block',
//     textAlign: 'left',
//     marginBottom: 5,
//     fontWeight: 400,
//     fontSize: 14,
//   },
// }));
const Footer = () => {
  // const classes = useStyles();

  return (
    <Grid
      container
      style={{
        bottom: 0,
        height: '20vh',
        overflow: 'hidden',
        marginTop: 'auto',
        padding: '55px 120px',
        backgroundColor: '#343043',
      }}
      direction="row"
      alignItems="center"
    >
      <Grid item xs={12} md={6} style={{ display: 'flex' }}>
        <img
          alt="casadi-logo-white"
          src="/casadi-logo-white.png"
          width="70"
          height="70"
        />
        <div
          style={{ border: '1px solid #FCFCFE', height: 70, margin: '0 22px' }}
        ></div>
        <Typography
          style={{
            fontSize: 12,
            width: 275,
            color: 'white',
            textAlign: 'left',
          }}
        >
          Prima școală particulară de șoferi profesionisti autorizată după 1989
          pentru pregătirea în vederea obținerii permisului de conducere a
          autovehiculelor din categoriile A,B,C,E și F
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container>
          {/* <Grid item xs={12} md={4}>
            <Link
              href="http://web.casadi.ro/legislate"
              color="textSecondary"
              className={classes.link}
            >
              Legislație
            </Link>
            <Link
              href="http://web.casadi.ro/terms"
              color="textSecondary"
              className={classes.link}
            >
              Termeni și condiții
            </Link>
            <Link
              href="http://web.casadi.ro/gdrp"
              color="textSecondary"
              className={classes.link}
            >
              GDPR
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Link
              href="http://web.casadi.ro/school"
              color="textSecondary"
              className={classes.link}
            >
              Școala de șoferi
            </Link>
            <Link
              href="http://web.casadi.ro/certificate"
              color="textSecondary"
              className={classes.link}
            >
              Atestate profesionale
            </Link>
            <Link href="#" color="textSecondary" className={classes.link}>
              Produse
            </Link>
          </Grid> 
          <Grid item xs={12} md={4}>
            <Link
              href="http://web.casadi.ro/contact"
              color="textSecondary"
              className={classes.link}
            >
              Contact
            </Link>
            <Link
              to="http://app.casadi.ro/"
              color="textSecondary"
              className={classes.link}
            >
              Chestionare online
            </Link>
              <Link href="#" color="textSecondary" className={classes.link}>
                Înscriere online
              </Link>
          </Grid>*/}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
