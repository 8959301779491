import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Container,
  InputAdornment,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MainContainer from '../../components/MainContainer';
import ForwardButton from '../../components/ForwardButton';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import casadiAPI from '../../utils/api';
import { errorHandling } from "../../utils/api"
import { useContextValue } from '../../state/context';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    width: '60%',
    padding: 60,
  },
  inputContainer: {
    marginBottom: 30,
  },
  input: {
    width: '50%',
  },
  lightText: {
    fontSize: 18,
    color: '#CECECE',
    marginBottom: 45,
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    color: '#615F69',
  },
}));

//TODO: request for better error supposrt from backend
const ChangePassword = () => {
  const classes = useStyles();
  const [{ user }, dispatch] = useContextValue();
  const [email, setEmail] = useState('');
  const [passwordOld, setPasswordOld] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const errorState = (err) => {
    setErrorMessage(err.response);
  }
  const setClientPassword = () => {
    const setPasswordData = new FormData();
    //const tokenData = new FormData();

    setPasswordData.append("old_password",passwordOld);
    setPasswordData.append("new_password",passwordConfirm);

    casadiAPI
        .post("/password_change", setPasswordData)
        .then((response) => {

        //     if (response.status === 200) {
        //         const user = response.data.data;

        //         tokenData.append("email", user.email);
        //         tokenData.append("password", password);
        //         tokenData.append("device_name", "android"); //TODO: check device

        //         casadiAPI
        //             .post("/token", tokenData)
        //             .then((response) => {
        //                 const token = response.data.data;
        //                 if (response.status === 200) {
        //                     const userData = { user, token };

        //                     storeUser(userData);
        //                     dispatch({ type: "signIn", user: userData });
        //                 }
        //             })
        //             .catch((err) => {
        //             });
        //     }
        // 
    })
        .catch((err) => errorState(err));
};

  return (
    <MainContainer contentStyle={{ margin: '80px 0' }}>
      <Container className={classes.container}>
        <Card className={classes.card}>
          <CardContent>
            {/* <Typography className={classes.lightText}>CONTUL MEU</Typography> */}
            <Typography color="primary" className={classes.title}>
            Schimbare parolă
            </Typography>
            <Typography className={classes.subtitle}>
            Schimbă parola pentru contul de utilizator Casadi
            </Typography>
            <div style={{ marginTop: 80 }}>
 
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  onChange={(event) => setPasswordOld(event.target.value)}
                  value={passwordOld}
                  helperText={
                    errorMessage?.password ? errorMessage?.password : ''
                  }
                  error={errorMessage?.password ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPasswordOld(!showPasswordOld)}
                          color="secondary"
                        >
                          {showPasswordOld ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        {errorMessage?.password && (
                          <ErrorOutlineIcon color="error" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                  variant="outlined"
                  label="Parola actuală"
                  type={showPasswordOld ? 'text' : 'password'}
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                  helperText={
                    errorMessage?.password ? errorMessage?.password : ''
                  }
                  error={errorMessage?.password ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          color="secondary"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        {errorMessage?.password && (
                          <ErrorOutlineIcon color="error" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                  variant="outlined"
                  label="Parola nouă"
                  type={showPassword ? 'text' : 'password'}
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  onChange={(event) => setPasswordConfirm(event.target.value)}
                  value={passwordConfirm}
                  helperText={
                    errorMessage?.password ? errorMessage?.password : ''
                  }
                  error={errorMessage?.password ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                          color="secondary"
                        >
                          {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        {errorMessage?.password && (
                          <ErrorOutlineIcon color="error" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                  variant="outlined"
                  label="Confirmare parolă nouă"
                  type={showPasswordConfirm ? 'text' : 'password'}
                />
              </div>
            </div>
          </CardContent>
          <ForwardButton onClick={()=> setClientPassword()}>Confirmă</ForwardButton>
          {/* <ForwardButton onClick={checkAPI}>CHECK</ForwardButton> */}
        </Card>
      </Container>
      <Snackbar
        // open={showSnackbar}
        autoHideDuration={6000}
        // onClose={closeSnackbar}
      >
        {/* <Alert onClose={closeSnackbar} severity="error">
          {errorMessage}
        </Alert> */}
      </Snackbar>
    </MainContainer>
  );
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default ChangePassword;
