import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useContextValue } from '../../state/context';
import ArrowBack from '@material-ui/icons/ArrowBack';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    borderRadius: '28px',
    marginLeft: '50px',
  },
  link: {
    textDecoration: 'none',
  },
}));

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const [{ user }, dispatch] = useContextValue();

  const logOut = () => {
    localStorage.removeItem('@user');
    dispatch({ type: 'removeUser' });
    history.push('/');
  };

  const goBack = () => {
    const url = window.location.href.split('/')

    if (url[url.length - 1] === 'results') {
      let item = JSON.parse(localStorage.lastProduct)
      history.push("/app/sub-products", { item })
    } else {
      history.goBack()
    }
  }

  return (
    <AppBar
      position="static"
      color="transparent"
      style={{ boxShadow: 'none', padding: '0 10%' }}
    >
      <Toolbar className={classes.toolbar}>
        <a href="https://casadi.ro/" target="_blank" rel="noopener noreferrer">
          <img
            alt="casadi-logo"
            src="/casadi-logo.png"
            style={{ width: '98px', height: '98px' }}
          />
        </a>{' '}
        {Object.keys(user).length === 0 && user.constructor === Object ? (
          <div className={classes.links}>
            <Link to="/register" className={classes.link}>
              <Button
                color="secondary"
                variant="outlined"
                className={classes.button}
              >
                Înregistrare
              </Button>
            </Link>
            <Link to="/" className={classes.link}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.button}
              >
                Autentificare
              </Button>
            </Link>
          </div>
        ) : (
          <div className={classes.links}>
            <Link to="/app/products" className={classes.link}>
              <Button>Produse</Button>
            </Link>
            <Link to="/app/settings" className={classes.link}>
              <Button>Settings</Button>
            </Link>
            <Button
              color="secondary"
              variant="contained"
              className={classes.button}
              startIcon={<AccountCircleOutlinedIcon />}
              onClick={() => logOut()}
            >
              Iesi din cont
            </Button>
          </div>
        )}

      </Toolbar>
      <Button
        onClick={ () => goBack() }
        style={{ 
          width: '100px',
          marginLeft: '24px'
        }}
      >
        <ArrowBack 
          style={{
            fontSize: 16,
            marginRight: '6px'
          }}
        />
        INAPOI
      </Button>
    </AppBar>
  );
};

export default Header;
