import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Container,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import MainContainer from '../../components/MainContainer';
import ForwardButton from '../../components/ForwardButton';

import casadiAPI, { errorHandling } from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    width: '60%',
    padding: 60,
  },
  inputContainer: {
    marginBottom: 30,
  },
  input: {
    width: '50%',
  },
  lightText: {
    fontSize: 18,
    color: '#CECECE',
    marginBottom: 45,
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    color: '#615F69',
  },
  toLoginBtn: {
    color: '#D23243',
  },
}));

//TODO: require error status for "confirmPassword" from backend

const Register = () => {
  const classes = useStyles();
  const history = useHistory();

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errorMessage, setErrorMessage] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const errorState = (error) => {
    errorHandling(error.response.config.url, error.response.status, error.response.data.message)
    if (error.response.status === 500) {
    } else if (error.response.status === 422) {
      setErrorMessage(error.response.data.message);
    }
  };

  const register = () => {
    const registerData = new FormData();

    registerData.append('phone', phone);
    registerData.append('email', email);
    registerData.append('password', password);
    registerData.append('password_confirmation', passwordConfirm);

    casadiAPI
      .post('/register', registerData)
      .then((response) => {
        if (response.status === 200) {
          history.push('/');
        }
      })
      .catch(errorState);
  };

  return (
    <MainContainer contentStyle={{ margin: '80px 0' }}>
      <Container className={classes.container}>
        <Card style={{ cursor: "default" }} className={classes.card}>
          <CardContent>
            <Typography className={classes.lightText}>CONTUL MEU</Typography>
            <Typography color="primary" className={classes.title}>
              Înregistrare
            </Typography>
            <Typography className={classes.subtitle}>
              Intră în contul de utilizator Casadi
            </Typography>
            <div style={{ marginTop: 80 }}>
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  onChange={(event) => setPhone(event.target.value)}
                  value={phone}
                  helperText={errorMessage?.phone ? errorMessage?.phone : ''}
                  error={errorMessage?.phone ? true : false}
                  InputProps={{
                    endAdornment: errorMessage?.phone && (
                      <InputAdornment position="end">
                        <ErrorOutlineIcon color="error" />
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                  variant="outlined"
                  label="Telefon"
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                  helperText={errorMessage?.email ? errorMessage?.email : ''}
                  error={errorMessage?.email ? true : false}
                  InputProps={{
                    endAdornment: errorMessage?.phone && (
                      <InputAdornment position="end">
                        <ErrorOutlineIcon color="error" />
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                  variant="outlined"
                  label="E-mail"
                />
              </div>

              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                  helperText={
                    errorMessage?.password ? errorMessage?.password : ''
                  }
                  error={errorMessage?.password ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          color="secondary"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        {errorMessage?.password && (
                          <ErrorOutlineIcon color="error" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                  variant="outlined"
                  label="Parolă"
                  type={showPassword ? 'text' : 'password'}
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  onChange={(event) => setPasswordConfirm(event.target.value)}
                  value={passwordConfirm}
                  helperText={
                    errorMessage?.confirmPassword
                      ? errorMessage?.confirmPassword
                      : ''
                  }
                  error={errorMessage?.confirmPassword ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          color="secondary"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                        {errorMessage?.confirmPassword && (
                          <ErrorOutlineIcon color="error" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                  variant="outlined"
                  label="Confirmare parolă"
                  type={showConfirmPassword ? 'text' : 'password'}
                />
              </div>
            </div>
            <Link className={classes.toLoginBtn} to="/">
              <span>Ai deja cont?</span>
            </Link>
          </CardContent>
          <ForwardButton onClick={register}>intră în Cont</ForwardButton>
        </Card>
      </Container>
    </MainContainer>
  );
};

export default Register;
