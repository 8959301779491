import React from "react";
import "./App.css";

import { ThemeProvider } from "@material-ui/core/styles";
import CasadiLightTheme from "./utils/theme/CasadiLightTheme";

//state
import StateProvider from "./state/context";
import context from "./state/context";
import reducer from "./state/reducer";

//routes
import RouteProvider from "./routes/RouteProvider";

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={CasadiLightTheme}>
                <StateProvider initialState={context} reducer={reducer}>
                        <RouteProvider />
                </StateProvider>
            </ThemeProvider>
        </div>
    );
}

export default App;
