import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Container,
  InputAdornment,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';

import MainContainer from '../../components/MainContainer';
import ForwardButton from '../../components/ForwardButton';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import casadiAPI from '../../utils/api';
import { errorHandling } from "../../utils/api"
import { useContextValue } from '../../state/context';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    width: '60%',
    padding: 60,
  },
  inputContainer: {
    marginBottom: 30,
  },
  input: {
    width: '50%',
  },
  lightText: {
    fontSize: 18,
    color: '#CECECE',
    marginBottom: 45,
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    color: '#615F69',
  },
  toResetPassword: {
    color: '#D23243',
  },
}));

//TODO: request for better error supposrt from backend
const Login = () => {
  const classes = useStyles();
  const [{ user }, dispatch] = useContextValue();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState({});

  const [showPassword, setShowPassword] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const errorState = (error) => {
    errorHandling(error.response.config.url, error.response.status, error.response.data.message)
    if (error.response?.status > 400) {
      setErrorMessage(error.response.data.message);

      if (
        typeof error.response.data.message === 'string' ||
        error.response.data.message instanceof String
      )
        setShowSnackbar(true);
    }
  };

  const storeUser = async (value) => {
    try {
      const userData = JSON.stringify(value);
      await localStorage.setItem('@user', userData);
      dispatch({ type: 'setUser', user: userData });
      history.push('/app/products');
    } catch (e) {
      // saving error
    }
  };

  const login = () => {
    const loginData = new FormData();
    const tokenData = new FormData();

    loginData.append('email_phone', email);
    loginData.append('password', password);

    tokenData.append('email', email);
    tokenData.append('password', password);
    tokenData.append('device_name', 'web');

    casadiAPI
      .post('/login', loginData)
      .then((response) => {
        if (response.status === 200) {
          const user = response.data.data;
          casadiAPI
            .post('/token', tokenData)
            .then((response) => {
              const token = response.data.data;
              if (response.status === 200) {
                const userData = { user, token };
                storeUser(userData);
              }
            })
            .catch((err) => {
            });
        }
      })
      .catch(errorState);
  };

  if (user.id) {
    history.push('/app/products');
  }

  return (
    <MainContainer contentStyle={{ margin: '80px 0' }}>
      <Container className={classes.container}>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.lightText}>CONTUL MEU</Typography>
            <Typography color="primary" className={classes.title}>
              Autentificare
            </Typography>
            <Typography className={classes.subtitle}>
              Intră în contul de utilizator Casadi
            </Typography>
            <div style={{ marginTop: 80 }}>
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  onChange={(event) => setEmail(event.target.value)}
                  value={email}
                  helperText={errorMessage?.email ? errorMessage?.email : ''}
                  error={errorMessage?.email ? true : false}
                  InputProps={{
                    endAdornment: errorMessage?.phone && (
                      <InputAdornment position="end">
                        <ErrorOutlineIcon color="error" />
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                  variant="outlined"
                  label="E-mail"
                />
              </div>
              <div className={classes.inputContainer}>
                <TextField
                  className={classes.input}
                  onChange={(event) => setPassword(event.target.value)}
                  value={password}
                  helperText={
                    errorMessage?.password ? errorMessage?.password : ''
                  }
                  error={errorMessage?.password ? true : false}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          color="secondary"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                        {errorMessage?.password && (
                          <ErrorOutlineIcon color="error" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  color="secondary"
                  variant="outlined"
                  label="Parolă"
                  type={showPassword ? 'text' : 'password'}
                />
              </div>
            </div>
            <Link className={classes.toResetPassword} to="/reset-password">
              <span>Ai uitat parola?</span>
            </Link>
          </CardContent>
          <ForwardButton onClick={login}>Intră în Cont</ForwardButton>
          {/* <ForwardButton onClick={checkAPI}>CHECK</ForwardButton> */}
        </Card>
      </Container>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </MainContainer>
  );
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default Login;
