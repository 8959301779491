const SetUser = 'setUser';
const RemoveUser = 'removeUser';
const SetToken = 'setToken';
const SetQuestionnaire = 'setQuestionnaire';
const SetQuizType = 'setQuizType';
const SetStatus = 'setStatus';
const SetFinishTime = 'setFinishTime';
const SetHardQuetion = 'SetHardQuetion';

const reducer = (state, action) => {
  switch (action.type) {
    case SetUser:
      return setUser(state, action.user);
    case RemoveUser:
      return removeUser(state);
    case SetToken:
      return setToken(state, action.token);
    case SetQuestionnaire:
      return setQuestionnaire(state, action.qData);
    case SetQuizType:
      return setQuizType(state, action.qType);
    case SetStatus:
      return setStatus(state, action.status);
    case SetFinishTime:
      return setFinishTime(state, action.finishTime);
    case SetHardQuetion:
      return setHardQuetion(state, action.hardQuestion);
    default:
      return state;
  }
};

export default reducer;

const setUser = (state, user) => ({
  ...state,
  user,
});

const removeUser = (state) => ({
  ...state,
  user: {},
});

const setToken = (state, token) => ({
  ...state,
  token,
});

const setQuestionnaire = (state, qData) => ({
  ...state,
  qData,
});

const setQuizType = (state, qType) => ({
  ...state,
  qType,
});

const setStatus = (state, status) => ({
  ...state,
  status,
});

const setHardQuetion = (state, hardQuestion) => ({
  ...state,
  hardQuestion,
});

const setFinishTime = (state, finishTime) => ({
  ...state,
  finishTime,
});
