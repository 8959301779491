import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Container,
  InputAdornment,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import MainContainer from '../../components/MainContainer';
import ForwardButton from '../../components/ForwardButton';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import casadiAPI from '../../utils/api';
import { errorHandling } from "../../utils/api"
import { useContextValue } from '../../state/context';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    width: '60%',
    padding: 60,
  },
  inputContainer: {
    marginBottom: 30,
  },
  input: {
    width: '50%',
  },
  lightText: {
    fontSize: 18,
    color: '#CECECE',
    marginBottom: 45,
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    color: '#615F69',
  },
}));

//TODO: request for better error supposrt from backend
const ResetPassword = () => {
  const classes = useStyles();
  const [{ user }, dispatch] = useContextValue();
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [step, setStep] = useState(1);
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const titles = [
    'Resetarea parolei',
    'Introduceți parola nouă',
    'Parola a fost schimbată cu succes'
  ];
  const subtitles = [
    'Introduceți numarul de telefon pentru a primi un cod de confirmare pentru resetarea parolei',
    'Introduceți codul primit și parola nouă',
    'Parola a fost schimbată cu succes, puteți trece la autorizare!',
  ];


  const [errorMessage, setErrorMessage] = useState({});

  const [showSnackbar, setShowSnackbar] = useState(false);

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  const errorState = (error) => {
    errorHandling(error.response.config.url, error.response.status, error.response.data.message)
    if (error.response?.status > 400) {
      setErrorMessage(error.response.data.message);

      if (
        typeof error.response.data.message === 'string' ||
        error.response.data.message instanceof String
      )
        setShowSnackbar(true);
    }
  };

  const getCode = () => {
    const loginData = new FormData();

    loginData.append('phone', phone);

    casadiAPI
      .post('/password_reset_code', loginData)
      .then((response) => {
        console.log('RESP: ', response.data);
        if (response.status === 200) {
          if (response.data.message === 'SMS send') {
            setStep(2);
          } else {
            setErrorMessage({ phone: response.data.message });
          }
        } else if (response.data.message) {
          setErrorMessage({ phone: response.data.message });
        }
      })
      .catch(errorState);
  };

  const resetPassword = () => {

    if (password !== passwordConfirm) {
      setErrorMessage({ "password": "Parolele introduse nu sunt identice" });
      return;
    }

    const passData = new FormData();

    passData.append('code', code);
    passData.append('password', password);

    casadiAPI
      .post('/password_reset', passData)
      .then((response) => {
        console.log('RESP: ', response.data);
        if (response.status === 200) {
          if (response.data.message === 'Password was updated') {
            setStep(3);
          } else {
            setErrorMessage({ phone: response.data.message });
          }
        }
      })
      .catch(errorState);
  }

  return (
    <MainContainer contentStyle={{ margin: '80px 0' }}>
      <Container className={classes.container}>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.lightText}>CONTUL MEU</Typography>
            <Typography color="primary" className={classes.title}>
              {titles[step - 1]}
            </Typography>
            <Typography className={classes.subtitle}>
              {subtitles[step - 1]}
            </Typography>
            <div style={{ marginTop: 80 }}>

              {
                step === 1 ?
                  <div className={classes.inputContainer}>
                    <TextField
                      className={classes.input}
                      onChange={(event) => setPhone(event.target.value)}
                      value={phone}
                      helperText={errorMessage?.phone ? errorMessage?.phone : ''}
                      error={errorMessage?.phone ? true : false}
                      InputProps={{
                        endAdornment: errorMessage?.phone && (
                          <InputAdornment position="end">
                            <ErrorOutlineIcon color="error" />
                          </InputAdornment>
                        ),
                      }}
                      color="secondary"
                      variant="outlined"
                      label="Phone"
                    />
                  </div>
                  : step === 2 ?
                    <>
                      <div className={classes.inputContainer}>
                        <TextField
                          className={classes.input}
                          onChange={(event) => setCode(event.target.value)}
                          value={code}
                          helperText={errorMessage?.code ? errorMessage?.code : ''}
                          error={errorMessage?.code ? true : false}
                          InputProps={{
                            endAdornment: errorMessage?.code && (
                              <InputAdornment position="end">
                                <ErrorOutlineIcon color="error" />
                              </InputAdornment>
                            ),
                          }}
                          color="secondary"
                          variant="outlined"
                          label="Code"
                        />
                      </div>
                      <div className={classes.inputContainer}>
                        <TextField
                          className={classes.input}
                          onChange={(event) => setPassword(event.target.value)}
                          value={password}
                          helperText={
                            errorMessage?.password ? errorMessage?.password : ''
                          }
                          error={errorMessage?.password ? true : false}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  color="secondary"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                {errorMessage?.password && (
                                  <ErrorOutlineIcon color="error" />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          color="secondary"
                          variant="outlined"
                          label="Parola nouă"
                          type={showPassword ? 'text' : 'password'}
                        />
                      </div>
                      <div className={classes.inputContainer}>
                        <TextField
                          className={classes.input}
                          onChange={(event) => setPasswordConfirm(event.target.value)}
                          value={passwordConfirm}
                          helperText={
                            errorMessage?.password ? errorMessage?.password : ''
                          }
                          error={errorMessage?.password ? true : false}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                                  color="secondary"
                                >
                                  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                {errorMessage?.password && (
                                  <ErrorOutlineIcon color="error" />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          color="secondary"
                          variant="outlined"
                          label="Confirmare parolă nouă"
                          type={showPasswordConfirm ? 'text' : 'password'}
                        />
                      </div>
                    </>
                    : <></>
              }
            </div>
          </CardContent>
          {
            step === 1 ?
              <ForwardButton onClick={() => getCode()}>Trimite cod</ForwardButton>
              : step === 2 ?
                <ForwardButton onClick={() => resetPassword()}>Resetează parola</ForwardButton>
                :
                <ForwardButton onClick={() => history.push('/')}>Logare</ForwardButton>
          }
        </Card>
      </Container>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </MainContainer>
  );
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default ResetPassword;
