import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Backdrop from "@material-ui/core/Backdrop";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import LinkIcon from '@material-ui/icons/Link';
import LibraryBooksIcon from '@material-ui/icons/Slideshow';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import casadiAPI from '../../utils/api'
import { errorHandling } from "../../utils/api"
import MaterilaContent from './materialContent'
import { useLocation, useHistory } from "react-router-dom";
import { useContextValue } from '../../state/context';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    width: 600,
    height: 343,
  },
  header: {
    display: "flex",
    alignItems: "center",
    font: "Oxygen",
    color: "#00415E",
    fontSize: 18,
    lineHeight: 2.2,
  },
  options: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 80,
  },
  reportButton: {
    display: "flex",
    justifyContent: "center",
  },
  customReport: {
    display: "flex",
    alignItems: "center",
  },
  inputLabel: {
    color: "black",
    marginLeft: 0,
  },
}));

const ChapterModal = ({ open, setOpen, id, testStatus, isActiveTestBtn }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1)
  const [materials, setMaterials] = useState({})
  const [activeMaterial, setActiveMaterial] = useState({})
  const [activeMaterialName, setActiveMaterialName] = useState(null)
  const [checkedTestStatus, setCheckedTestStatus] = useState(false)
  const history = useHistory()
  // eslint-disable-next-line
  const [{}, dispatch] = useContextValue()
  const { state } = useLocation();
  const { questionnaire } = state;

  const userProductId = localStorage.userProductId || null
  const subProductId = localStorage.subProductId || null

  const handleClose = () => {
    setOpen(false);
  }

  const getChapterMaterials = (id) => {
    id && casadiAPI
      .get(`/chapter-materials/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setMaterials(response.data)
        }
      })
      .catch((e) => {
        errorHandling(e.response.config.url, e.response.status, e.response.data.message)
      })
  }

  const refreshLearningPage = () => {
    const userProductId = localStorage.userProductId || null
    const productId = localStorage.subProductId || null
    const qCreateData = new FormData()

    qCreateData.append("subproduct_id", productId)
    qCreateData.append("user_product_id", userProductId)
    qCreateData.append("questionnaire_type_id", 1)
    
    casadiAPI
      .get(`/sub-product-chapters/${productId}/${userProductId}`)
      .then(({ data }) => {
        const questionnaire = data
        questionnaire.type = 'scoalaSQ'
        history.push("/app/remote-learning", { questionnaire })
      })
      .catch((e) => {
        errorHandling(e.response.config.url, e.response.status, e.response.data.message)
        alert("Users Product dont find: " + e)
      })
  }

  const setTestStatusValue = event => {
    event.preventDefault()

    casadiAPI
      .get(`/chapter-materials-passed/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setCheckedTestStatus(true)
          refreshLearningPage()
        }
      })
      .catch((e) => {
        errorHandling(e.response.config.url, e.response.status, e.response.data.message)
      })
  }

  const openQ = (id) => {
    localStorage.setItem('isChapter', false)
    const qCreateData = new FormData()
    qCreateData.append("subproduct_id", subProductId)
    qCreateData.append("user_product_id", userProductId)
    qCreateData.append("questionnaire_type_id", 1)
    qCreateData.append("chapter_id", id)

    casadiAPI
      .post('/questionnaire-create', qCreateData)
      .then((response) => {
        if (response.status === 200) {
          const questionnaire = response.data
          localStorage.setItem('questionnaire', JSON.stringify(questionnaire))
          storeQData(questionnaire, 1)
        }
      })
      .catch((e) => {
        errorHandling(e.response.config.url, e.response.status, e.response.data.message)
        alert(e.response.data)
      })
  }

  const storeQData = async (value) => {
    try {
      dispatch({ type: "setQuestionnaire", qData: value.data })
      dispatch({
        type: "setQuizType",
        qType: questionnaire.type === 1 ? `scoalaSQ` : `atestatSQ`,
      })
      dispatch({ type: "setStatus", status: "started" })
    } catch (e) {
    } finally {
      localStorage.setItem('qType', JSON.stringify({type: questionnaire.type, qType: questionnaire.type === 1 ? `scoalaSQ` : `atestatSQ`}))
      questionnaire.type !== 1
        ? history.push("/app/certificate")
        : history.push("/app/chestionar-scoala")
    }
  }

  React.useEffect(() => {
    getChapterMaterials(id)
    setPage(1)
    if (testStatus) setCheckedTestStatus(true)
    else setCheckedTestStatus(false)
    
    // eslint-disable-next-line
  }, [testStatus, id])

  return (
    <div>
      <Dialog
        aria-labelledby="transition-modal-title"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <DialogContent>
          <div className="material-types" style={{ display: page === 2 ? 'none' : 'block' }}>
            <h2 id="transition-modal-title" className={classes.header}>
              <MenuBookIcon
                  style={{ fontSize: 36, color: "#333", marginRight: 20 }}
              />
              Material Types:
            </h2>
            <div className="material-types__list">

              { Object.keys(materials).length &&  Object.keys(materials).map(key => (
                <div
                  key={key} 
                  className="material-types__item" 
                  onClick={() => {
                    setPage(2);
                    setActiveMaterial(materials[key])
                    setActiveMaterialName(key)} 
                  }
                  data-disabled={!Boolean(materials[key].length)}
                >
                  { key === 'books' ? (<LibraryBooksIcon />) : (<LinkIcon />) }
                  { key === 'books' ? 'prezentation' : key }
                </div>
              ))
              }
            </div>
            <FormGroup>
              <FormControlLabel 
                control={
                  <Switch 
                    disabled={ checkedTestStatus }
                    checked={ checkedTestStatus }
                    onInput={ event => setTestStatusValue(event) } 
                  />
                }
                label="Familiarized with the material"
              />
            </FormGroup>
          </div>
          <MaterilaContent 
            chapterId={ id } 
            content={ activeMaterial } 
            display={ page === 1 ? false : true}  
            activeMaterialName={ activeMaterialName }
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start' }}>
          {page === 1 ?
            (<Button onClick={ () => setOpen(false) }>Close</Button>) :
            (<Button onClick={ () => setPage(1) }>Back</Button>)
          }
          {(checkedTestStatus && isActiveTestBtn) ? 
            (<Button onClick={ () => openQ(id) }>Test</Button>) :
            (<></>)
          }
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChapterModal;
