import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InfoIcon from '@material-ui/icons/Info';
import { Button } from '@material-ui/core';
import { styled } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    width: 600,
    height: 223,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    font: 'Oxygen',
    color: '#00415E',
    fontSize: 18,
    lineHeight: 2.3,
    fontWeight: 'normal',
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 80,
  },
  confirmButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
}));

const ConfirmModal = ({
  openConfirmModal,
  setOpenConfirmModal,
  nextQuestion,
  setAnswer,
  type,
  setShowAnswers,
  wrongAnswers,
  setWrongAnswers,
  disableQuestion
}) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpenConfirmModal(false)
  };

  const handleConfirm = () => {
    if (setShowAnswers) setShowAnswers(true)

    if (type === 'CertificateSQ') {
      nextQuestion(setAnswer)
      nextQuestion(true)
    } else {
      // if (setWrongAnswers) {
      //   setWrongAnswers(wrongAnswers + 1)
      // }
      if (disableQuestion) {
        disableQuestion(true)
      }
    }
    handleClose();
  };

  const MyInfoIcon = styled(InfoIcon)({
    root: {
      color: '#828282',
    },
  });

  const text = {
    CertificateSQ: ` Salvați raspunsul?
    După salvare nu veți mai putea accesa această întrebare`,
    SchoolSQ: `Răspunsul deja selectat va fi salvat și nu va mai putea fi modificat. Continuați?`,
    CertificateTQ: `Răspunsul deja selectat va fi salvat și nu va mai putea fi modificat. Continuați?`,
  };



  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openConfirmModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openConfirmModal}>
          <div className={classes.paper}>
            <p id="transition-modal-title" className={classes.header}>
              <MyInfoIcon
                style={{
                  fontSize: 60,
                  marginRight: 20,
                }}
              />
              {text[type]}
            </p>
            <p id="transition-modal-description">
              {/* react-transition-group animates me. */}
            </p>
            <div className={classes.confirmButton}>
              <Button
                variant="outlined"
                disableRipple
                style={{
                  width: 143,
                  height: 52,
                  marginLeft: 20,
                  marginRight: 50,
                  backgroundColor: '#27AE60',
                  border: 'none',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                onClick={() => handleConfirm()}
              >
                {type === 'SchoolSQ' ? 'DA' : 'OK'}
              </Button>
              <Button
                color="secondary"
                style={{
                  width: 143,
                  height: 52,
                  color: 'white',
                  fontWeight: 'bold',
                  backgroundColor: '#CECECE',
                }}
                onClick={() => handleClose()}
              >
                {type === 'SchoolSQ' ? 'NU' : 'Închide'}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
