import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

const NotFound = () => {
  return (
    <Fragment>
      <Link style={{ textDecoration: 'none' }} to="/">
        <Button
          variant="contained"
          color="secondary"
          style={{ width: 180, height: 67 }}
        >
          ACASĂ
        </Button>
      </Link>
    </Fragment>
  );
};

export default NotFound;
