import React, { createContext, useContext, useReducer } from "react";
import reducer from "../reducer";

export const StateContext = createContext();

const state = {
    user: {},
    qData: {
        questions: [],
    },
    qType: '',
    hardQuestion: null
};
function StateProvider({ children }) {
    return (
        <StateContext.Provider value={useReducer(reducer, state)}>
            {children}
        </StateContext.Provider>
    );
}

export const useContextValue = () => useContext(StateContext);
export default StateProvider;