import React, { useState, useCallback } from 'react';
import { Typography, Container, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Dropzone from 'react-dropzone';

import PublishIcon from '@material-ui/icons/Publish';
import CheckIcon from '@material-ui/icons/Check';

import MainContainer from '../../components/MainContainer';

import casadiAPI from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    marginBottom: 30,
  },
  input: {
    width: '70%',
  },
  lightText: {
    fontSize: 18,
    color: '#CECECE',
    marginBottom: 45,
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  subtitle: {
    fontSize: 18,
    margin: '0 20%',
    color: '#615F69',
  },
  fileName: {
    textAlign: 'left',
    paddingLeft: 16,
    fontSize: 18,
    fontWeight: 700,
    color: '#4282AB',
  },
  fileInput: {
    width: 280,
    height: 280,
    marginBottom: 55,
  },
}));

//TODO: create a custom dropzone component, and finalize file upload

const UploadFiles = () => {
  const classes = useStyles();

  const [status, setStatus] = useState(false);
  const [drag, setDrag] = useState(false);
  const [cazier, setCazier] = useState({});

  const fileDrop = (file) => {
    setStatus(true);
    setDrag(false);
    setCazier(file);
  };

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      // reader.onabort = () => console.log('file reading was aborted');
      // reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;

        fileDrop(file);
      };

      // reader.readAsArrayBuffer(file);
      reader.readAsDataURL(file);
    });
    // setStatus(true);
  }, []);

  const uploadFile = () => {
    const uploadData = new FormData();

    uploadData.append('type', 'cazier');
    uploadData.append('file', cazier.file);

    casadiAPI.post('/user/document', uploadData);
  };

  return (
    <MainContainer contentStyle={{ margin: '80px 25%' }}>
      <Container direction="row" justify="center">
        <Typography className={classes.lightText}>
          ACHIZIȚIONARE PRODUS
        </Typography>
        <Typography color="primary" className={classes.title}>
          Încărcare documente
        </Typography>
        <Typography className={classes.subtitle}>
          Încarcă imagini pentru următoarele documente. Te rugăm ca textul și
          pozele de pe acestea să fie lizibile.
        </Typography>
        <Grid container style={{ marginTop: 150, padding: '0 150px' }}>
          <Grid item xs={12} md={6}>
            <Typography className={classes.fileName}>
              Cazierul judiciar
            </Typography>
            <Dropzone
              onDrop={onDrop}
              onDragEnter={() => setDrag(true)}
              onDragLeave={() => setDrag(false)}
            >
              {({ getRootProps, getInputProps }) => (
                <Button
                  {...getRootProps()}
                  variant="outlined"
                  style={
                    drag
                      ? { borderColor: '#00415E', color: '#00415E' }
                      : status
                        ? { borderColor: '#6AD688', color: '#6AD688' }
                        : {}
                  }
                  className={classes.fileInput}
                // onClick={() => setStatus(!status)}
                >
                  {status ? <CheckIcon /> : <PublishIcon />}
                  <input {...getInputProps()} />
                </Button>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.fileName}>
              Cazierul judiciar
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.fileInput}
            >
              <PublishIcon />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.fileName}>
              Cazierul judiciar
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.fileInput}
            >
              <PublishIcon />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classes.fileName}>
              Cazierul judiciar
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.fileInput}
            >
              <PublishIcon />
            </Button>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          style={{ width: 180, height: 67 }}
          onClick={uploadFile}
        >
          CONTINUĂ
        </Button>
      </Container>
    </MainContainer>
  );
};

export default UploadFiles;
