import React, { useEffect, useState } from 'react';
import MainContainer from '../../components/MainContainer';
import { 
  Container
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import FileViewer from 'react-file-viewer';



const RemoteLearning = () => {
  const { state: file } = useLocation()
  const [fileType, setFileType] = useState(null)

  useEffect(() => {
    setFileType(getFileType())
    // eslint-disable-next-line
  }, [])

  const getFileType = () => {
    let fileTypeArray =  file.split('.')
    let fileType = fileTypeArray[fileTypeArray.length - 1]

    return fileType
  }
  
  return (
    <MainContainer>
      <div className='cs-container'>
        <Container>
          <FileViewer
            fileType={fileType}
            filePath={file}
            // headers={
            //   {
            //     "Access-Control-Allow-Origin": "*",
            //     "Access-Control-Expose-Headers": "Set-Cookie",
            //     "Access-Control-Allow-Headers": `Origin, X-Requested-With, Access-Control-Expose-Headers,Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Access-Control-Allow-Methods, Set-Cookie`,
            //     "Access-Control-Allow-Methods": "GET, OPTIONS, PATCH, POST, PUT, DELETE",
            //     "Access-Control-Allow-Credentials": true,
            //     "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            //   }
            // }
            />
       </Container>
      </div>
    </MainContainer>
  );
};
export default RemoteLearning;
