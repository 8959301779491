import axios from "axios";

const casadiAPI = axios.create({
  // baseURL: "https://staging.casadi.ro/api",
  baseURL: "https://api.casadi.ro/api",
  headers: {
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Expose-Headers": "Set-Cookie",
    // "Access-Control-Allow-Headers": `Origin, X-Requested-With, Access-Control-Expose-Headers,Access-Control-Allow-Origin, Access-Control-Allow-Credentials, Access-Control-Allow-Methods, Set-Cookie`,
    // "Access-Control-Allow-Methods": "GET, OPTIONS, PATCH, POST, PUT, DELETE",
    // "Access-Control-Allow-Credentials": true,
    // "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    // "x-ratelimit-remaining": 59
  }
})

export const errorHandling = (url, status, message) => {
  // const errorData = {
  //   user_id : JSON.parse(localStorage.getItem('@user')).user.id,
  //   app:"web",
  //   url: url,
  //   error_status : status,
  //   error_message : message
  // }

  const errorData = new FormData();

  if (JSON.parse(localStorage.getItem('@user')))
    errorData.append('user_id', JSON.parse(localStorage.getItem('@user')).user.id)
  errorData.append('app', 'web')
  errorData.append('url', url)
  errorData.append('error_status', status)
  errorData.append('error_message', message)

  casadiAPI.post('api/log-errors', errorData);
}

casadiAPI.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('@user'));

  if (user) {
    config.headers = {
      'Authorization': `Bearer ${user.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }
  return config;
})

export default casadiAPI;
