import React from "react";

const BlueTriangle = () => {
    return (
        <svg
            style={{ position: "absolute", zIndex: "1", top: 160, right: 0 }}
            width="665"
            height="808"
            viewBox="0 0 679 820"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.5557 364.312L665.58 6.65268C698.909 -12.0014 740 12.0895 740 50.2836V769.287C740 807.578 698.72 831.658 665.389 812.81L26.3649 451.467C-7.57243 432.277 -7.46531 383.354 26.5557 364.312Z"
                fill="#00415E"
            />
        </svg>
    );
};

export default BlueTriangle;
