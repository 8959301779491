import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ChooseProduct from '../ChooseProduct';
import ConfirmMatriculation from '../ConfirmMatriculation';
import PersonalData from '../PersonalData';
import UploadFiles from '../UploadFiles';
import SchoolQ from '../SchoolQ/SchoolQ';
import CertificateQ from '../CertificateQ';
import Products from '../Products';
import Profile from '../Profile';
import Retrospective from '../Retrospective';
import { withRouter } from 'react-router-dom';
import Results from '../Results';
import SubProducts from '../SubProducts';
import RemoteLearning from '../RemoteLearning';
import FileView from '../FileView';
import ChangePassword from '../ChangePassword';
import Settings from '../Settings';


const AppRoutes = () => {
  return (
    <Switch>
      <Route path="/app/alege-produs">
        <ChooseProduct />
      </Route>
      <Route path="/app/chestionar-scoala">
        <SchoolQ />
      </Route>
      <Route path="/app/certificate">
        <CertificateQ />
      </Route>
      <Route path="/app/confirmare-inscriere">
        <ConfirmMatriculation />
      </Route>
      <Route path="/app/scoala-date">
        <PersonalData />
      </Route>
      <Route path="/app/incarcare-documente">
        <UploadFiles />
      </Route>
      <Route path="/app/products">
        <Products />
      </Route>
      <Route path="/app/profile">
        <Profile />
      </Route>
      <Route path="/app/results">
        <Results />
      </Route>
      <Route path="/app/retrospective">
        <Retrospective />
      </Route>
      <Route path="/app/sub-products">
        <SubProducts />
      </Route>
      <Route path="/app/remote-learning">
        <RemoteLearning />
      </Route>
      <Route path="/app/file">
        <FileView />
      </Route>
      <Route path="/app/change-password">
        <ChangePassword />
      </Route>
      <Route path="/app/settings">
        <Settings />
      </Route>
    </Switch>
  );
};

export default withRouter(AppRoutes);
