import React from "react"
import MenuBookIcon from "@material-ui/icons/MenuBook"
import { makeStyles } from "@material-ui/core/styles"
import Link from "@material-ui/core/Link"
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    header: {
      display: "flex",
      alignItems: "center",
      font: "Oxygen",
      color: "#00415E",
      fontSize: 18,
      lineHeight: 2.2,
    }
}))

const MaterialContent = ({ content, display, chapterId, activeMaterialName }) => {
    const classes = useStyles()
    const history = useHistory()

    // const downloadChapterMaterials = (uuid, name) => {
    // casadiAPI
    //     .get(`/download-material/${uuid}`)
    //     .then((response) => {
    //         if (response.status === 200) {
    //             const url = window.URL.createObjectURL(new Blob([response.data]))
    //             const link = document.createElement('a')
    //             link.href = url
    //             link.setAttribute('download', name)
    //             document.body.appendChild(link)
    //             link.click()
    //         }
    //     })
    //     .catch((e) => {
    //         alert(e.response.data)
    //     })
    // }

    const openChapterMaterial = link => {
        history.push("/app/file", link)
    }

    return (
        <div className="material-detail" style={{ display: display ? 'block' : 'none' }}>
            <h2 id="transition-modal-title" className={classes.header}>
            <MenuBookIcon
                style={{ fontSize: 36, color: "#333", marginRight: 20 }}
            />
                Material detail:
            </h2>
            <ol>
                { content.length && content.map((item, index) => (
                    <li key={ index }>
                        { item.name ? (
                            <Link
                                className="material-link"
                                onClick={ () => openChapterMaterial(item.url) }
                            >
                                { item.name }
                            </Link>
                        ) : (
                            <Link href={ item } target="_blank">
                                { item } 
                            </Link>
                        ) }
                    </li>
                )) }
            </ol>
        </div>
    )
}

export default MaterialContent