import React, { useState, useRef, useEffect } from 'react';
import { Typography } from '@material-ui/core';

import { useContextValue } from '../../state/context';

const DisplayTimer = ({ timeLimitMinutes, colors, isShowHour }) => {
  const [{ status }, dispatch] = useContextValue();
  const [timer, setTimer] = useState(timeLimitMinutes * 60);
  const decrement = useRef(null);

  const formatTime = () => {
    const minutes = `${Math.floor(timer / 60)}`;
    const hours = `0${Math.floor(timer / 60 / 60)}`.slice(-2);
    const getSeconds = `0${timer % 60}`.slice(-2);
    const getMinutes = `0${minutes % 60}`.slice(-2);

    return (isShowHour ? `${hours}:` : '') + `${getMinutes}:${getSeconds}`;
  };

  const getFinishTime = () => {
    const minutes = `${Math.floor((timeLimitMinutes * 60 - timer) / 60)}`;
    const getSeconds = `0${(timeLimitMinutes * 60 - timer) % 60}`.slice(-2);
    const getMinutes = `0${minutes % 60}`.slice(-2);

    return `${getMinutes}:${getSeconds}`;
  };

  const handleTimer = () => {
    setTimer((timer) => timer - 1);
  };

  useEffect(() => {
    if (status === 'finished') {
      clearInterval(decrement.current);
      setTimer(0);
      dispatch({ type: 'setFinishTime', finishTime: getFinishTime() });
    }

    if (status === 'started') {
      setTimer(timeLimitMinutes * 60);
      clearInterval(decrement.current);
      decrement.current = setInterval(handleTimer, 1000);
    }
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    clearInterval(decrement.current);

    decrement.current = setInterval(handleTimer, 1000);

    return function cleanup() {
      clearInterval(decrement.current);
      setTimer(0);
    };
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(decrement.current);
      setTimer(0);
      dispatch({ type: 'setStatus', status: 'finished' });
    }
    // eslint-disable-next-line
  }, [timer]);

  return (
    <span>
      <Typography
        style={{
          color: '#00415E',
          font: 'Oxygen',
          fontSize: 18,
          fontWeight: 700,
        }}
      >
        {formatTime()}
      </Typography>
    </span>
  );
};

export default DisplayTimer;
