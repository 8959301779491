import React from "react"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core"

const AlertDialog = ({ open, setOpen, openQ, alertMessage, passedPopup, setPassedPopup }) => {

    const handleClose = () => {
        setOpen(false)
        setPassedPopup(false)

        if (localStorage.isExamPopup === 'true') {
            const openQDetail = JSON.parse(localStorage.openQ)
            const product = openQDetail.product
            const type = openQDetail.type
            openQ(null, product, type)
        }
    }

    const handleCloseQ = () => {
        setOpen(false)
        setPassedPopup(false)
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Alert"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {alertMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {passedPopup ? 
                    (
                    <Button 
                        onClick={handleCloseQ} 
                        autoFocus
                    >
                        Cancel
                    </Button>
                    ) : (<></>)
                }
                <Button 
                    onClick={handleClose} 
                    autoFocus
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertDialog