import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Grow,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
} from "@material-ui/core";
import casadiAPI from "../../utils/api";
import { errorHandling } from "../../utils/api"
import { makeStyles } from "@material-ui/core/styles";
import { images } from "../../resources";
import MainContainer from "../../components/MainContainer";
import TextField from "../../components/TextField";
import { useContextValue } from "../../state/context";
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  card: {
    width: "210px",
    height: "210px",
    marginRight: "30px",
    marginBottom: "30px",
  },
  media: {
    marginTop: "10px",
    height: "120px",
    width: "120px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  modalButton: {
    width: 150,
    margin: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    width: 10,
    height: 10,
  },
  loadingScreen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center", 
    margin: "auto",
    flexDirection: "column",
    height: "100%",
  },
}));


const Products = () => {
  const [userProducts, setUserProducts] = useState(null);
  // const [selectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [selectedItemType] = useState("");
  const [changedExpired, setChangedExpired] = useState(false);
  const [userFullName, setUserFullName] = useState(false);
  // eslint-disable-next-line
  const [{ }, dispatch] = useContextValue();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getProducts();
    setUserName()
  }, []);

  useEffect(() => {
    if (changedExpired) {
      setLoading(true);
      getProducts();
      setChangedExpired(false);
    }
  }, [changedExpired]);

  const setUserName = () => {
    let userData = JSON.parse(localStorage['@user'])
    let fullName = userData.user.firstname + ' ' + userData.user.lastname
    setUserFullName(fullName)
  }

  const getProducts = () => {
    casadiAPI
      // .get('/active-user-product')
      .get("/user-products")
      .then((response) => {
        if (response.status === 200) {
          const products = [...response.data.data];
          // const products = Object.values(response.data.data);

          const options = products.map((obj) => {
            if (obj) {
              return {
                label: `${obj.name}`,
                value: obj.id,
                quizType: obj.product_type,
                obj: obj,
              };
            } else return false
          });
          setUserProducts(options);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorHandling(err.response.config.url, err.response.status, err.response.data.message)
      });
  };
 
  const onPressItem = (item, itemIndex) => {
    localStorage.setItem('userProductId', item.obj.user_product_id)
    localStorage.setItem('lastProduct', JSON.stringify(item))
    history.push("/app/sub-products", { item })
  }

  return (
    <MainContainer>
      <div className="cs-container">
        <Grid container spacing={10}>
          {loading ? (
            <div className={classes.loadingScreen}>
              <CircularProgress />
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              <TextField  name={userFullName}/>
            <h2>Produse</h2>
            <div
              style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
            >
              {userProducts &&
              userProducts.map((item, index) =>
                // NEW API doesn't have a show_expires field
                // TODO: fix conditional statement
                !item.obj.show_expires ? (
                  <Grow
                    key={index}
                    in={!loading}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(loading ? {} : { timeout: index * 200 })}
                  >
                    <Card className={classes.card}>
                      <CardActionArea onClick={() => onPressItem(item)}>
                        <CardMedia
                          className={classes.media}
                          component="img"
                          alt="Product image"
                          height="140"
                          image={
                            item.quizType === 1
                              ? images.ic_scoala
                              : images.ic_atestate
                          }
                          title="Product image"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="h2">
                            {item.label}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grow>
                ) : null
              )}
            </div>
            </div>
          )}
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Products;
