import React, { useState, useEffect } from 'react';
import MainContainer from '../../components/MainContainer';
import { Container, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TimerIcon from '@material-ui/icons/Timer';
import { useContextValue } from '../../state/context';
import casadiAPI, { errorHandling } from '../../utils/api';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '73vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  sucessText: {
    color: '#6AD688',
    fontSize: 22,
    lineHeight: 2,
    textAlign: 'center',
    letterSpacing: 0.15,
    fontWeight: 'bold',
  },
  failText: {
    color: '#D23243',
    fontSize: 22,
    lineHeight: 2,
    textAlign: 'center',
    letterSpacing: 0.15,
    fontWeight: 'bold',
  },
  score: {
    fontWeight: 'bold',
    color: '#00415E',
    lineHeight: 2,
    letterSpacing: 0.15,
    fontSize: 20,
    textAlign: 'center',
    marginTop: 35,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    margin: '20px auto',
    marginTop: 100,
  },
  time: {
    fontWeight: 'bold',
    color: '#00415E',
    lineHeight: 2,
    letterSpacing: 0.15,
    fontSize: 20,
  },
  timeData: {
    margin: ' 0px auto',
    display: 'flex',
    alignItems: 'center',
  },
}));

const Results = () => {
  const [{ status, finishTime, qData }, dispatch] = useContextValue();
  const classes = useStyles();
  const { state } = useLocation();
  const { rightAnswers, totalQ, passPercentage } = state;
  const [isSuccess, setIsSuccess] = useState('');
  const history = useHistory();
  const [qType, setQType] = useState(null)
  const userProductId = localStorage.userProductId || null
  const subProductId = localStorage.subProductId || null

  const ActionButton = withStyles({
    root: {
      borderRadius: 50,
      height: 40,
      transform: 'scale(1.3)',
      borderColor: '#4282AB',
      color: '#4282AB',
      marginBottom: 25,
      filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14))',
    },
  })((props) => <Button color="default" style={{ fontSize: 12 }} {...props} />);

  const handleShowRetrospective = () => {
    history.push('/app/retrospective');
  };


  const startNew = () => {
    if (!qType) {
      const item = JSON.parse(localStorage.lastProduct)
      history.push("/app/sub-products", { item })
    }
    else {
      localStorage.setItem('openMaterials', true)
      casadiAPI
        .get(`/sub-product-chapters/${localStorage.subProductId}/${localStorage.userProductId}`)
        .then(({ data }) => {
          const questionnaire = data
          history.push("/app/remote-learning", { questionnaire })
        })
        .catch((e) => {
          errorHandling(e.response.config.url, e.response.status, e.response.data.message)
          alert("Users Product dont find: " + e)
        })
    }
  }

  const reStart = () => {
    openQ(localStorage.chapterId)
  }

  const backToProduct = () => {
    const item = JSON.parse(localStorage.lastProduct)
    history.push("/app/sub-products", { item })
  }

  const openQ = (id = null) => {

    const qCreateData = new FormData()
    qCreateData.append("subproduct_id", subProductId)
    qCreateData.append("user_product_id", userProductId)
    qCreateData.append("questionnaire_type_id", JSON.parse(localStorage.lastProduct).obj.product_type)
    id && qCreateData.append("chapter_id", id)

    casadiAPI
      .post('/questionnaire-create', qCreateData)
      .then((response) => {
        if (response.status === 200) {
          const questionnaire = response.data
          storeQData(questionnaire, 1)
        }
      })
      .catch((e) => {
        errorHandling(e.response.config.url, e.response.status, e.response.data.message)
        alert(e.response.data)
      })
  }

  const storeQData = async (value) => {
    try {
      dispatch({ type: "setQuestionnaire", qData: value.data })
      dispatch({
        type: "setQuizType",
        qType: JSON.parse(localStorage.qType).type === 1 ? `scoalaSQ` : `atestatSQ`,
      })
      dispatch({ type: "setStatus", status: "started" })
    } catch (e) {
      errorHandling(e.response.config.url, e.response.status, e.response.data.message)
    } finally {
      JSON.parse(localStorage.qType).type !== 1
        ? history.push("/app/certificate")
        : history.push("/app/chestionar-scoala")
    }
  }

  useEffect(() => {
    if (passPercentage && (Math.abs(rightAnswers) / totalQ) * 100 >= passPercentage) {
      setIsSuccess(true);
      submitQuestionnaire(true);
    } else {
      setIsSuccess(false);
      submitQuestionnaire(false);
    }

    // eslint-disable-next-line
  }, []);

  const submitQuestionnaire = (bool) => {
    const leftTime = finishTime?.split(':') || '00:00';
    const leftTimeMinutes = qData.time - (Math.round(+leftTime[0] + +leftTime[1] / 60) || 1);
    if (status === 'finished') {
      const submitData = new FormData();

      submitData.append('questionnaire_id', qData.id);
      submitData.append('passed', bool ? 1 : 0);
      submitData.append('no_of_questions', qData.length);
      submitData.append('no_of_correct_answers', rightAnswers);
      submitData.append('no_of_wrong_answers', qData.length - rightAnswers);
      submitData.append('total_time', qData.time);
      submitData.append('left_time', leftTimeMinutes);
      submitData.append('questions', JSON.stringify(qData.questions));

      casadiAPI
        .post('/questionnaire-update', submitData)
        .then((response) => {
          if (response.status === 200) {
            setQType(response.data.data.chapter_id)
          }
        })
        .catch((err) => {
          errorHandling(err.response.config.url, err.response.status, err.response.data.message)
        });
    }
  };

  if (!qData || !qData.questions.length) {
    history.push('/app/products');
  }
  return (
    <MainContainer>
      <Container className={classes.container}>
        {isSuccess ? (
          <div className={classes.sucessText}>
            Felicitări! <br />
            Ați fost declarat ADMIS la proba teoretică.
          </div>
        ) : (
          <div className={classes.failText}>
            Ne pare rău... <br />
            Ați fost declarat RESPINS la proba teoretică.
          </div>
        )}
        <div className={classes.score}>
          Punctaj {`${Math.abs(rightAnswers)}/${totalQ}`}
        </div>
        <div className={classes.timeData}>
          <TimerIcon
            style={{ color: '#D23243', marginRight: 5, fontSize: 32 }}
          />
          <div className={classes.time}>{finishTime}</div>
        </div>
        <div className={classes.buttonWrapper}>
          <ActionButton variant="outlined" onClick={handleShowRetrospective}>
            Retrospectivă
          </ActionButton>
          <ActionButton variant="outlined" onClick={backToProduct}>
            Inapoi la produs
          </ActionButton>
          {!isSuccess && JSON.parse(localStorage.qType).qType.substr(-2) !== 'TQ' &&
            (<ActionButton variant="outlined" onClick={reStart}>
              Incearca din nou
            </ActionButton>)
          }
          {
            isSuccess && JSON.parse(localStorage.getItem("isChapter")) ?
              <ActionButton variant="outlined" onClick={startNew}>
                Capitolul urmator
              </ActionButton> : ''
          }

        </div>
      </Container>
    </MainContainer>
  );

};

export default Results;
