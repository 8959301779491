import React, { useState, useEffect } from 'react';

import MainContainer from '../../components/MainContainer';
import TimerIcon from '@material-ui/icons/Timer';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import ReplayIcon from '@material-ui/icons/Replay';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import { withStyles } from '@material-ui/core/styles';
import { Container, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useContextValue } from '../../state/context';
import Question from '../../components/questionnaire/Question';
import ReportQuestionModal from '../../components/ReportModal';
import DisplayTimer from '../../components/DisplayTimer';
import ConfirmModal from '../../components/ConfirmModal';
import FinishedPopup from '../../components/FinishedPopup';
import { useHistory, Prompt } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    width: '100%',
    backgroundColor: 'white',
    border: '0.5px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 81,
  },
  cardElement: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    font: 'Oxygen',
    fontWeight: 'normal',
  },
  cardElementTimer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    font: 'Oxygen',
    fontWeight: 'normal',
  },
  questionnaireTime: {
    marginLeft: 10,
    color: '#00415E',
    fontWeight: 'bold',
  },
  contentWrapper: {
    marginTop: 30,
    textAlign: 'left',
  },
  questionNumber: {
    color: '#00415E',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 18,
  },
  question: {
    marginTop: 10,
    fontSize: 20,
    color: '#343043',
    fontWeight: 700,
    maxWidth: 700,
  },
  answerCardWrapper: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
  },
  answerCard: {
    height: 83,
    margin: 8,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  answerMarker: {
    backgroundColor: '#F2F2F2',
    height: '100%',
    width: 100,
    color: '#828282',
    fontWeight: 700,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  answer: {
    marginLeft: 25,
    color: '#828282',
    fontSize: 18,
  },
  reportProblem: {
    marginTop: 40,
    marginBottom: 10,
    color: '#828282',
    display: 'flex',
    alignContent: 'flex-start',
  },
  reportText: {
    marginLeft: 4,
    cursor: 'pointer',
  },
  explanation: {
    marginTop: 40,
    marginBottom: 10,
    color: '#2D9CDB',
    display: 'flex',
    alignContent: 'flex-start',
  },
  actionOptions: {
    border: '1px solid #4282AB',
    height: 139,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: -10,
  },
  paginationButtons: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  explanationContainer: {
    marginTop: 50,
  },
  additionalOptions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  explanationHeader: {
    fontFamily: 'Oxygen',
    display: 'flex',
    textTransform: 'uppercase',
    fontSize: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  explanationNr: {
    fontFamily: 'Oxygen',
    fontWeight: 700,

    alignSelf: 'center',
    marginLeft: 5,
    fontSize: 20,
  },
  explanationText: {
    fontFamily: 'Oxygen',
    textAlign: 'left',
    color: '#615F69',
    fontSize: 18,
    marginTop: 20,
  },
}));

const SchoolQ = () => {
  const [{ qData, qType }, dispatch] = useContextValue();
  const classes = useStyles();
  const [current, setCurrent] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [open, setOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [showAnswers, setShowAnswers] = useState(false);
  const [showExplanation, setExplanation] = useState(false);
  const [isFinished, setIsFinished] = useState(false)
  const [openFinishedPopup, setOpenFinishedPopup] = useState(false)
  const [isRepeat, setIsRepeat] = useState(false)
  const hardQuestionAttempts = localStorage.hardQuestionAttempts
  const cazHardQuestionAttempts = localStorage.cazHardQuestionsAttempts
  let history = useHistory();

  const CasadiButton = withStyles((theme) => ({
    root: {
      color: '#D23243',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25);',
      backgroundColor: '#F2F2F2',
    },
  }))(Button)

  const prevQuestion = (setAnswer) => {
    let prevQ = current - 1;

    if (prevQ >= 0) {
      setCurrent(prevQ);
    }
  };

  window.onbeforeunload = (event) => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
      e.returnValue =
        'Părăsirea acestei pagini vă rezulta în pierderea progresului. Continuați?';
    }
    return 'Părăsirea acestei pagini vă rezulta în pierderea progresului. Continuați?';
  };

  const modifyAnswers = () => {
    setSelected([]);
  };

  const userInput = () => {
    if (qData.questions[current].answered) {
      return qData.questions[current].selected;
    } else {
      return selected;
    }
  };

  const checkPastPercentage = (percent, questionsCount, isRight) => {
    const minWrongAnswers = Math.floor(questionsCount * ((100 - percent) / 100))
    // const minRightAnswers = questionsCount - minWrongAnswers
    let rightAnswersCount = rightAnswers
    let wrongAnswersCount = wrongAnswers

    isRight ? rightAnswersCount++ : wrongAnswersCount++

    if (qType.substr(-2) === 'TQ') {
      if ((wrongAnswersCount !== 0 && wrongAnswersCount >= minWrongAnswers)) {
        dispatch({ type: 'setStatus', status: 'finished' })
        setIsFinished(true)
      }
    }
  }

  const nextQuestion = (setAnswer) => {
    const nextQD = { ...qData };
    let notAnsweresQuestions = []

    nextQD.questions[current] = {
      ...nextQD.questions[current],
      answered: qData?.questions[current].answered || setAnswer,
      status:
        setAnswer &&
        !showAnswers &&
        verifyAnswer(selected, qData?.questions[current].answers),
      selected,
    };

    qData.questions.forEach(question => {
      if (!question.answered) {
        notAnsweresQuestions.push(question)
      }
    })

    let nextQ = current + 1;
    // if (notAnsweresQuestions.length > 1 && !isRepeat) {
    if (notAnsweresQuestions.length >= 1) {
      if (nextQ + 1 <= qData.questions.length) {
        setCurrent(nextQ)
      } else {
        setIsRepeat(true)
        goNotAnsweredQuestion()
      }
    } else if (notAnsweresQuestions.length >= 1 && isRepeat) {
      goNotAnsweredQuestion()
    } else if (hardQuestionAttempts && +hardQuestionAttempts > 0) {
      openHardQ()
    } else if (cazHardQuestionAttempts && +cazHardQuestionAttempts > 0) {
      openCazHardQ()
    }
    else {
      if (hardQuestionAttempts) {

        const hardQwAnswers = setHardQResult(nextQD)
        const hardQuestionResultData = localStorage.hardQuestionResultData
          ? JSON.parse(localStorage.hardQuestionResultData)
          : []

        hardQuestionResultData.questions.forEach(q => {
          if (hardQwAnswers.includes(q.id)) q.status = false
        })

        try {
          dispatch({ type: 'setQuestionnaire', qData: hardQuestionResultData })
          dispatch({ type: 'setStatus', status: 'finished' })
        } catch (error) {
        } finally {
          history.push('/app/results', {
            rightAnswers: hardQuestionResultData.questions.length - hardQwAnswers.length,
            wrongAnswers: hardQwAnswers.length,
            totalQ: hardQuestionResultData.questions.length,
            passPercentage: 100,
          })
        }
      }
      else if (cazHardQuestionAttempts) {
        const cazHardQwAnswers = setCazHardQResult(nextQD)
        const cazHardQuestionResultData = localStorage.cazHardQuestionResultData
          ? JSON.parse(localStorage.cazHardQuestionResultData)
          : []

        cazHardQuestionResultData.questions.forEach(q => {
          if (cazHardQwAnswers.includes(q.id)) q.status = false
        })

        try {
          dispatch({ type: 'setQuestionnaire', qData: cazHardQuestionResultData })
          dispatch({ type: 'setStatus', status: 'finished' })
        } catch (error) {
        } finally {
          history.push('/app/results', {
            rightAnswers: cazHardQuestionResultData.questions.length - cazHardQwAnswers.length,
            wrongAnswers: cazHardQwAnswers.length,
            totalQ: cazHardQuestionResultData.questions.length,
            passPercentage: 100,
          })
        }
      }

      dispatch({ type: 'setStatus', status: 'finished' })
      setIsFinished(true)
    }

    if (!hardQuestionAttempts) {
      dispatch({ type: 'setQuestionnaire', qData: nextQD });
    } else if (!cazHardQuestionAttempts) {
      dispatch({ type: 'setQuestionnaire', qData: nextQD });
    }

    setAnswer &&
      !showAnswers &&
      verifyAnswer(selected, qData.questions[current].answers);
    if (showAnswers) {
      setShowAnswers(false);
    }
    setSelected([])
  }

  const goNotAnsweredQuestion = () => {
    for (let i = 0; i < qData.questions.length; i++) {
      if (!qData.questions[i].answered) {
        setCurrent(i)
        break
      }
    }
  }

  const openHardQ = () => {
    const nextQD = { ...qData }
    let questions = nextQD.questions

    questions = questions.filter(q => !q.status)
    questions.map(q => {
      q.answered = false
      q.status = 1
      q.selected = []
    })
    nextQD.questions = questions

    localStorage.hardQ = JSON.stringify(nextQD)
    localStorage.hardQuestionAttempts -= 1

    refreshQState()
    window.location.href = '/app/chestionar-scoala'
  }

  const openCazHardQ = () => {
    const nextQD = { ...qData }
    let questions = nextQD.questions

    questions = questions.filter(q => !q.status)
    questions.map(q => {
      q.answered = false
      q.status = 1
      q.selected = []
    })
    nextQD.questions = questions

    localStorage.cazHardQ = JSON.stringify(nextQD)
    if (cazHardQuestionAttempts !== '0') localStorage.cazHardQuestionsAttempts -= 1

    refreshQState()
    // window.location.reload()
    window.location.href = '/app/chestionar-scoala'
    // window.location.href = '/app/chestionar-scoala'
  }

  const setHardQResult = (nextQD) => {

    if (localStorage.hardQuestionAttempts === '0') {
      const hardQWrongAnsweredQ = localStorage.hardQWrongAnsweredQ
        ? JSON.parse(localStorage.hardQWrongAnsweredQ)
        : []

      nextQD.questions.forEach(q => {
        if (!q.status) hardQWrongAnsweredQ.push(q.id)
      })

      localStorage.hardQWrongAnsweredQ = JSON.stringify(hardQWrongAnsweredQ)

      return hardQWrongAnsweredQ
    }
  }

  const setCazHardQResult = (nextQD) => {
    if (localStorage.cazHardQuestionsAttempts === '0') {
      const cazHardQWrongAnsweredQ = localStorage.cazHardQWrongAnsweredQ
        ? JSON.parse(localStorage.cazHardQWrongAnsweredQ)
        : []

      nextQD.questions.forEach(q => {
        if (!q.status) cazHardQWrongAnsweredQ.push(q.id)
      })

      localStorage.cazHardQWrongAnsweredQ = JSON.stringify(cazHardQWrongAnsweredQ)

      return cazHardQWrongAnsweredQ
    }
  }

  const refreshQState = () => {
    setCurrent(0)
    setSelected([])
    setRightAnswers(0)
    setWrongAnswers(0)
    setOpen(false)
    setOpenConfirmModal(false)
    setShowAnswers(false)

    return false
  }

  const disableQuestion = setAnswer => {
    const nextQD = { ...qData };

    nextQD.questions[current] = {
      ...nextQD.questions[current],
      answered: qData?.questions[current].answered || setAnswer,
      status: verifyAnswer(selected, qData?.questions[current].answers),
      selected,
    };

    setSelected([])
  }

  const toggleModal = () => {
    setOpen(!open);
  };

  const arraysEqual = (_arr1, _arr2) => {
    if (
      !Array.isArray(_arr1) ||
      !Array.isArray(_arr2) ||
      _arr1.length !== _arr2.length
    ) {
      return false;
    }

    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  };

  const verifyAnswer = (currentAnswer, answers) => {
    let questionAnswers = 0;
    let correctIndexes = [];

    answers.forEach((obj, index) => {
      if (obj.valid) {
        correctIndexes.push(obj.id);
        questionAnswers++;
      }
    });

    if (currentAnswer.length === questionAnswers) {
      if (arraysEqual(currentAnswer, correctIndexes)) {
        setRightAnswers(rightAnswers + 1);
        checkPastPercentage(qData.pass_percentage, qData.questions.length, true)
        return true;
      } else {
        setWrongAnswers(wrongAnswers + 1);
      }
    } else {
      setWrongAnswers(wrongAnswers + 1);
    }

    checkPastPercentage(qData.pass_percentage, qData.questions.length, false)
    return false;
  };

  if (!qData || !qData.questions.length) {
    if (localStorage.hardQuestionAttempts) {
      const hardQ = localStorage.hardQ ? JSON.parse(localStorage.hardQ) : {}
      if (hardQ?.questions && hardQ.questions.length) {
        dispatch({ type: "setQuestionnaire", qData: hardQ })
        dispatch({ type: "setQuizType", qType: 'scoalaSQ' })
        dispatch({ type: "setStatus", status: "started" })
      } else if (!hardQ.questions.length && localStorage.hardQuestionAttempts) {
        const hardQuestionResultData = localStorage.hardQuestionResultData
          ? JSON.parse(localStorage.hardQuestionResultData)
          : []
        try {
          dispatch({ type: 'setQuestionnaire', qData: hardQuestionResultData })
          dispatch({ type: 'setStatus', status: 'finished' })
        } catch (error) {
          // errorHandling(error.response.config.url, error.response.status, error.message)
        } finally {
          history.push('/app/results', {
            rightAnswers: hardQuestionResultData.questions.length,
            wrongAnswers: 0,
            totalQ: hardQuestionResultData.questions.length,
            passPercentage: 100,
          })
        }
      }
    } else if (localStorage.cazHardQuestionsAttempts) {
      const cazHardQ = localStorage.cazHardQ ? JSON.parse(localStorage.cazHardQ) : {}
      if (cazHardQ?.questions && cazHardQ.questions.length) {
        dispatch({ type: "setQuestionnaire", qData: cazHardQ })
        dispatch({ type: "setQuizType", qType: 'scoalaSQ' })
        dispatch({ type: "setStatus", status: "started" })
      }
      else if (!cazHardQ.questions.length && localStorage.cazHardQuestionsAttempts) {
        const cazhardQuestionResultData = localStorage.cazHardQuestionsAttempts
          ? JSON.parse(localStorage.cazhardQuestionResultData)
          : []
        try {
          dispatch({ type: 'setQuestionnaire', qData: cazhardQuestionResultData })
          dispatch({ type: 'setStatus', status: 'finished' })
        } catch (error) {
          // errorHandling(error.response.config.url, error.response.status, error.message)
        } finally {
          history.push('/app/results', {
            rightAnswers: cazhardQuestionResultData.questions.length,
            wrongAnswers: 0,
            totalQ: cazhardQuestionResultData.questions.length,
            passPercentage: 100,
          })
        }
      }
    } else {
      history.push('/app/products')
    }
  }

  useEffect(() => {
    if (isFinished) {
      if ((rightAnswers / qData.questions.length) * 100 >= qData.pass_percentage) {
        history.push('/app/results', {
          rightAnswers,
          wrongAnswers,
          totalQ: qData.questions.length,
          passPercentage: qData.pass_percentage,
        })
      } else {
        setOpenFinishedPopup(true)
      }
    }

    // eslint-disable-next-line
  }, [isFinished]);

  useEffect(
    () => () => {
      //remove onbeforeunload
      window.onbeforeunload = function () { };
    },
    []
  );

  return (
    qData &&
    qData.questions.length && (
      <MainContainer>
        <ConfirmModal
          setOpenConfirmModal={setOpenConfirmModal}
          openConfirmModal={openConfirmModal}
          nextQuestion={nextQuestion}
          setAnswer={false}
          type={'SchoolSQ'}
          setShowAnswers={setShowAnswers}
          wrongAnswers={wrongAnswers}
          setWrongAnswers={setWrongAnswers}
          disableQuestion={disableQuestion}
        />
        <FinishedPopup
          setOpenFinishedPopup={setOpenFinishedPopup}
          openFinishedPopup={openFinishedPopup}
          rightAnswers={rightAnswers}
          wrongAnswers={wrongAnswers}
          setRightAnswers={setRightAnswers}
          setWrongAnswers={setWrongAnswers}
          setCurrent={setCurrent}
          setIsFinished={setIsFinished}
        />
        <Container className={classes.container}>
          <div className={classes.card}>
            <div className={classes.cardElement}>
              {qData.questions.length}
              <div>Initiale</div>
            </div>
            <div className={classes.cardElement}>
              {qData.questions.length - (rightAnswers + wrongAnswers)}
              <div>Rămase</div>
            </div>
            <div className={classes.cardElementTimer}>
              <TimerIcon style={{ color: '#D23243' }} />
              <span className={classes.questionnaireTime}>
                <DisplayTimer timeLimitMinutes={qData.time} />
              </span>
            </div>
            <div className={classes.cardElement}>
              <div style={{ fontWeight: 'bold', color: '#D23243' }}>
                {wrongAnswers}
              </div>
              <div>Gresite</div>
            </div>
            <div className={classes.cardElement}>
              <div style={{ fontWeight: 'bold', color: '#4282AB' }}>
                {rightAnswers}
              </div>
              <div>Corecte</div>
            </div>
          </div>
        </Container>
        {qType === 'scoalaSQ' && (
          <Container className={classes.paginationButtons}>
            {current !== 0 ?
              (<CasadiButton onClick={() => prevQuestion()} variant="contained">
                Precedentă
              </CasadiButton>
              ) :
              (<CasadiButton onClick={() => prevQuestion()} disabled>
                Precedentă
              </CasadiButton>
              )
            }

            {(current + 1) !== qData.questions.length ?
              (<CasadiButton onClick={() => nextQuestion()} variant="contained">
                Următoare
              </CasadiButton>
              ) :
              (<CasadiButton onClick={() => nextQuestion()} disabled>
                Următoare
              </CasadiButton>
              )
            }
          </Container>
        )}
        <Question
          question={qData.questions[current]}
          questionNumber={current}
          selected={userInput()}
          setSelected={setSelected}
          showAnswers={showAnswers || (!hardQuestionAttempts && qData.questions[current].answered)}
          setShowAnswers={setShowAnswers}
          qType={qType}
        />

        {showExplanation && (
          <Container className={classes.explanationContainer}>
            <div style={{ display: 'flex', marginBottom: 15 }}>
              <div className={classes.explanationHeader}>
                EXPLICAȚIE ÎNTREBARE -
              </div>
              <div className={classes.explanationNr}>
                Răspuns corect{' '}
                {(
                  qData.questions[current].answers.findIndex((x) => x.valid) +
                  10
                )
                  .toString(36)
                  .toUpperCase()}
              </div>
            </div>

            <div className={classes.explanationText}>
              {qData.questions[current].explanation}
            </div>
          </Container>
        )}
        <Container className={classes.additionalOptions}>
          <div className={classes.reportProblem}>
            {' '}
            <ReportProblemOutlined />
            <div className={classes.reportText} onClick={toggleModal}>
              Raportează întrebare
            </div>
            <ReportQuestionModal
              setOpen={setOpen}
              open={open}
              questionId={qData?.questions[current]?.id}
            />
          </div>
          {qType === 'scoalaSQ' && (
            <div className={classes.explanation}>
              {' '}
              {showAnswers || qData.questions[current].answered ? (
                qData.questions[current].explanation ? (
                  <div style={{ display: 'flex' }}>
                    <InfoIcon />
                    <div
                      className={classes.reportText}
                      onClick={() => setExplanation(!showExplanation)}
                    >
                      Explicație
                    </div>
                  </div>
                ) : null
              ) : (
                <div style={{ display: 'flex' }}>
                  <InfoIcon />
                  <div
                    className={classes.reportText}
                    onClick={() => setOpenConfirmModal(!openConfirmModal)}
                  >
                    Răspuns corect
                  </div>
                </div>
              )}
            </div>
          )}
        </Container>
        <div key={qData.questions[current]} className={classes.actionOptions}>
          <Button
            variant="outlined"
            color="secondary"
            style={{
              width: 250,
              cursor: 'pointer',
              height: 77,
              marginLeft: 20,
              border: '1px solid #27AE60',
              color: '#615F69',
              fontWeight: 700,
              backgroundColor: qData.questions[current].answered
                ? '#E5E5E5'
                : 'none',
            }}
            // disabled={qData.questions[current].answered}
            onClick={() => nextQuestion(false)}
          >
            <ReplayIcon style={{ marginRight: 5 }} /> Răspunde mai târziu
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            style={{
              width: 250,
              height: 77,
              marginLeft: 20,
              cursor: 'pointer',
              border: '1px solid #27AE60',
              color: '#D23243',
              backgroundColor: qData.questions[current].answered
                ? '#E5E5E5'
                : 'none',
            }}
            onClick={modifyAnswers}
            disabled={qData.questions[current].answered}
          >
            <CancelIcon style={{ marginRight: 5 }} />
            MODIFICĂ RĂSPUNS
          </Button>
          <Button
            variant="outlined"
            style={{
              width: 250,
              height: 77,
              marginLeft: 20,
              backgroundColor: qData.questions[current].answered
                ? 'gray'
                : '#27AE60',
              border: 'none',
              color: 'white',
              cursor: 'pointer',
              fontWeight: 700,
            }}
            disabled={qData.questions[current].answered}
            onClick={() => nextQuestion(true)}
          >
            Trimit răspunsul
          </Button>
        </div>
        <Prompt
          when={!isFinished}
          message={() =>
            `Părăsirea acestei pagini vă rezulta în pierderea progresului. Continuați?`
          }
        />
      </MainContainer>
    )
  );
};

export default SchoolQ;
