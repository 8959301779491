import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ReportProblemOutlined from '@material-ui/icons/ReportProblemOutlined';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import casadiAPI, { errorHandling } from '../../utils/api';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    width: 600,
    height: 343,
  },
  header: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    font: 'Oxygen',
    color: '#00415E',
    fontSize: 18,
    lineHeight: 2.2,
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 80,
  },
  reportButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  customReport: {
    display: 'flex',
    alignItems: 'center',
  },
  inputLabel: {
    color: 'black',
    marginLeft: 0,
  },
}));

const ReportQuestionModal = ({ open, setOpen, questionId }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState('');
  const [customInput, setCustomInput] = useState(false);
  const [customReport, setCustomReport] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectReportReason = (e) => {
    if (e.target.value === checked) {
      setChecked('');
    } else {
      setChecked(e.target.value);
      setCustomInput('');
      setCustomReport('');
    }
  };

  const setAnotherReason = () => {
    setCustomInput(!customInput);
    if (!customInput) {
      setChecked('');
    }
  };

  const handleInput = (e) => {
    setCustomReport(e.target.value);
  };

  const submitReport = () => {
    const questionData = new FormData();

    questionData.append('question_id', questionId);
    questionData.append('reason', customInput ? customReport : checked);
    casadiAPI
      .post('/report', questionData)
      .then((response) => {
        if (response.status === 200) {
          // const questionnaire = response.data.data;
          setOpen(false);
        }
      })
      .catch((err) => {
        errorHandling(err.response.config.url, err.response.status, err.response.data.message)
      });
  };

  const BlueCheckbox = withStyles({
    root: {
      color: '#615F69',
      '&$checked': {
        color: '#4282AB',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <p id="transition-modal-title" className={classes.header}>
              <ReportProblemOutlined
                style={{ fontSize: 60, color: '#828282', marginRight: 20 }}
              />
              Motivul pentru care se raportează întrebarea:
            </p>
            <p id="transition-modal-description">
              {/* react-transition-group animates me. */}
            </p>
            <div className={classes.options}>
              <FormControlLabel
                control={
                  <BlueCheckbox
                    checked={checked === 'Wrong question'}
                    onChange={handleSelectReportReason}
                    value={'Wrong question'}
                    color="primary"
                  />
                }
                label="Întrebare greșită"
              />
              <FormControlLabel
                control={
                  <BlueCheckbox
                    checked={checked === 'Wrong answer'}
                    onChange={handleSelectReportReason}
                    value={'Wrong answer'}
                    color="primary"
                  />
                }
                label="Răspuns greșit"
              />
              <FormControlLabel
                control={
                  <BlueCheckbox
                    checked={checked === 'Wrong image'}
                    onChange={handleSelectReportReason}
                    value={'Wrong image'}
                    color="primary"
                  />
                }
                label="Imagine Gresita"
              />
              <div className={classes.customReport}>
                <FormControlLabel
                  style={{ marginRight: 0 }}
                  control={
                    <BlueCheckbox
                      checked={customInput}
                      onChange={setAnotherReason}
                      color="primary"
                    />
                  }
                />
                {customInput ? (
                  <Input onChange={handleInput} />
                ) : (
                  <InputLabel className={classes.inputLabel}>
                    Alt motiv
                  </InputLabel>
                )}
              </div>
            </div>
            <div className={classes.reportButton}>
              <Button
                variant="outlined"
                onClick={submitReport}
                style={{
                  width: 165,
                  height: 52,
                  backgroundColor: '#00415E',
                  border: 'none',
                  color: 'white',
                  fontWeight: 700,
                  marginTop: 40,
                }}
              >
                RAPORTEAZĂ
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ReportQuestionModal;
