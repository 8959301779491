import React, { useEffect } from 'react';
import { Card, Typography, Container, Checkbox } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'center',
    display: 'flex',
  },
  card: {
    width: '100%',
    backgroundColor: 'white',
    border: '0.5px solid #E0E0E0',
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 81,
  },
  cardElement: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentWrapper: {
    marginTop: 30,
    textAlign: 'left',
  },
  questionNumber: {
    color: '#00415E',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 18,
  },
  question: {
    marginTop: 10,
    fontSize: 20,
    color: '#343043',
    fontWeight: 700,
  },
  answerCardWrapper: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 800,
    maxWidth: 800,
    width: 800,
    // maxWidth: 800,
  },
  answerCardRetrospective: {
    marginTop: 50,
    display: 'flex',
    flexDirection: 'column',
    width: '65%',
  },
  answerCard: {
    height: 83,
    margin: 8,
    paddingRight: 25,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
  },
  selectedAnswerCard: {
    height: 83,
    margin: 8,
    paddingRight: 25,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#D1EDFF',
  },
  correctAnswer: {
    height: 83,
    margin: 8,
    paddingRight: 25,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#C8FFD7',
  },
  wrongAnswer: {
    height: 83,
    margin: 8,
    paddingRight: 25,
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: '#FFD6DA',
  },
  selectedAnswerMarker: {
    backgroundColor: '#D1EDFF',
    height: '100%',
    minWidth: 100,
    fontWeight: 700,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#00415E',
  },
  answerMarker: {
    backgroundColor: '#F2F2F2',
    height: '100%',
    minWidth: 100,
    color: '#828282',
    fontWeight: 700,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  correctAnswerMarker: {
    backgroundColor: '#C8FFD7',
    height: '100%',
    minWidth: 100,
    color: '#00415E',
    fontWeight: 700,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrongAnswerMarker: {
    backgroundColor: '#FFD6DA',
    height: '100%',
    minWidth: 100,
    color: '#00415E',
    fontWeight: 700,
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  answerOptionsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 800,
  },
  answerOptionsRetrospective: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  answerWrp: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  answer: {
    marginLeft: 25,
    paddingRight: 15,
    color: '#828282',
    fontSize: 18,
    textAlign: 'left',
  },
  selectedAnswer: {
    marginLeft: 25,
    paddingRight: 15,
    color: '#00415E',
    fontSize: 18,
    textAlign: 'left',
  },
  reportProblem: {
    marginTop: 40,
    marginBottom: 10,
    color: '#828282',
    display: 'flex',
    alignContent: 'flex-start',
  },
  reportText: {
    marginLeft: 4,
  },
  actionOptions: {
    border: '1px solid #4282AB',
    height: 139,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: -10,
  },
  questionImg: {
    marginTop: 50,
    width: 400,
  },
  checkIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const Question = ({
  question,
  questionNumber,
  selected,
  setSelected,
  qType,
  showAnswers,
  mode,
  editMode,
}) => {
  const classes = useStyles();

  const BlueCheckbox = withStyles({
    root: {
      color: '#615F69',
      transform: 'scale(1.4)',
      '&$checked': {
        color: '#4282AB',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const GreenCheckbox = withStyles({
    root: {
      color: '#12CC61',
      fill: '#12CC61',
      transform: 'scale(1.4)',
      '&$checked': {
        color: '#12CC61',
        fill: '#12CC61',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const RedCheckbox = withStyles({
    root: {
      color: '#D23243',
      fill: '#D23243',
      transform: 'scale(1.4)',
      '&$checked': {
        color: '#D23243',
        fill: '#D23243',
      },
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

  const highlightAnswer = (answer) => {
    if (showAnswers) {
      if (qType === 'scoalaSQ' || qType === 'atestatSQ') {
        if (isCorrect(answer.id)) {
          return classes.correctAnswer;
        }
        if (isSelected(answer.id) && !isCorrect(answer.id)) {
          return classes.wrongAnswer;
        } else {
          return classes.answerCard;
        }
      }
    } else {
      if (qType === 'scoalaSQ' || qType === 'scoalaTQ') {
        return isSelected(answer.id)
          ? classes.selectedAnswerCard
          : classes.answerCard;
      } else {
        return classes.answerCard;
      }
    }
  };

  const highlightMarker = (answer) => {
    if (showAnswers) {
      if (qType === 'scoalaSQ' || qType === 'atestatSQ') {
        if (isCorrect(answer.id)) {
          return classes.correctAnswerMarker;
        }
        if (isSelected(answer.id) && !isCorrect(answer.id)) {
          return classes.wrongAnswerMarker;
        } else {
          return classes.answerMarker;
        }
      }
    } else {
      if (qType === 'scoalaSQ' || qType === 'scoalaTQ') {
        return isSelected(answer.id)
          ? classes.selectedAnswerMarker
          : classes.answerMarker;
      } else {
        return classes.answerMarker;
      }
    }
  };

  const selectAnswer = (index) => {
    if (!showAnswers) {
      if (selected.indexOf(index) === -1) {
        setSelected([...selected, index]);
      } else {
        setSelected(selected.filter((item) => item !== index));
      }
    }
  };

  const isSelected = (id) => {
    if (selected.indexOf(id) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const isCorrect = (id) => {
    return question.answers.find((question) => question.id === id)?.valid;
  };

  useEffect(() => {
    if (mode === 'retrospective' && question) {
      if (question.selected) {
        setSelected(selected);
      }
    }
    // eslint-disable-next-line
  }, [mode]);

  return (
    <div>
      <Container className={classes.contentWrapper}>
        <Typography className={classes.questionNumber}>
          Nr. {questionNumber + 1}
        </Typography>
        <Typography color="primary" className={classes.question}>
          {question.title}
        </Typography>
      </Container>
      <Container
        className={
          mode === 'retrospective'
            ? classes.answerOptionsRetrospective
            : classes.answerOptionsWrapper
        }
      >
        <div
          className={
            mode === 'retrospective'
              ? classes.answerCardRetrospective
              : classes.answerCardWrapper
          }
        >
          {' '}
          {question.answers.map((answer, index) => (
            <Card
              key={index}
              className={highlightAnswer(answer) + ` ${qType}`}
              onClick={() => {
                selectAnswer(answer.id)
                highlightAnswer(answer)
              }}
              data-checked={
                isSelected(answer.id)}
            >
              {qType === 'scoalaSQ' || qType === 'scoalaTQ' ? (
                <div className={highlightMarker(answer)}>
                  {(index + 10).toString(36).toUpperCase()}
                </div>
              ) : (
                <div
                  style={{
                    minWidth: 100,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {showAnswers &&
                    qType === 'atestatSQ' &&
                    isSelected(answer.id) ? (
                    isCorrect(answer.id) ? (
                      <GreenCheckbox checked={true} />
                    ) : (
                      <RedCheckbox
                        checkedIcon={<ClearIcon />}
                        checked={true}
                      />
                    )
                  ) : isCorrect(answer.id) && showAnswers ? (
                    <GreenCheckbox checked={isSelected(answer.id)} />
                  ) : (
                    <BlueCheckbox
                      checked={isSelected(answer.id)}
                      onClick={() => selectAnswer(answer.id)}
                    />
                  )}
                </div>
              )}
              <div className={classes.answerWrp}>
                <div
                  className={
                    isSelected(answer.id)
                      ? classes.selectedAnswer
                      : classes.answer
                  }
                >
                  {answer.answer}
                </div>
                <div className={classes.checkIcon}>
                  {/* TODO: Rewrite this line  */}
                  {showAnswers &&
                    qType === 'scoalaSQ' &&
                    isSelected(answer.id) ? (
                    isCorrect(answer.id) ? (
                      <CheckIcon style={{ fill: '#12CC61' }} />
                    ) : (
                      <ClearIcon style={{ fill: '#D23243' }} />
                    )
                  ) : isCorrect(answer.id) &&
                    showAnswers &&
                    qType === 'scoalaSQ' ? (
                    <CheckIcon style={{ fill: '#12CC61' }} />
                  ) : null}
                </div>
              </div>
            </Card>
          ))}
        </div>
        {question.image_path && (
          <img className={classes.questionImg} src={question.image_path} alt={'img'} />
        )}
      </Container>
    </div>
  );
};

export default Question;
